import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogComponent } from './../dialog/dialog.component';
import { EventItemSingleComponent } from '../eventitem-single/eventitem-single.component';

import { application } from './../core/globals';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { ModulesService } from './../core/services/data/modules.service';
import { HtmlTextsService } from './../core/services/data/htmltexts.service';
import { IModule } from '../core/interfaces/IModule';
import { IHtmlTextExtended } from '../core/interfaces/IHtmlTextExtended';

@Component({
  selector: 'app-htmltexts-summary',
  templateUrl: './htmltexts-summary.component.html',
  styleUrls: ['./htmltexts-summary.component.css']
})

export class HtmlTextsSummaryComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditEventItem: MatDialogRef<EventItemSingleComponent>;

  private displayColumnsAll: string[] = ['moduleID', 'module', 'edit'];
  private displayedColumns: string[] = this.displayColumnsAll;

  public modules: IModule[] = [];

  private filterModuleID: number = null;

  private textFilterModule: string = application.getRawText('pwp.htmltexts.summary.filter.htmltextsmodule.title');

  private moduleID: number = null;
  private htmlTexts: IHtmlTextExtended[] = [];
  private htmlTextsDataSource = new MatTableDataSource(this.htmlTexts);

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private modulesService: ModulesService,
    private htmlTextsService: HtmlTextsService)
  {
    this.security.CheckForFeature('events');

    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
  };

  private ngOnInit()
  {
    this.loadHtmlTexts();
    //this.loadModules();
  };

  //private loadModules()
  //{
  //  this.modulesService.GetHtmlTextsForSelection().subscribe((_modules) =>
  //  {
  //    this.modules = _modules;
  //  });
  //};

  private onModuleFilterChanged()
  {
    this.loadHtmlTexts();
  };

  private delete(_htmlText: IHtmlTextExtended)
  {
    this.moduleID = _htmlText.moduleID;
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('pwp.htmltexts.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
    {
      if (_action.action !== 'yes')
      {
        return;
      }

      //this.htmlTextsService.DeleteHtmlText(_htmlText.moduleID).subscribe((_result: IReturnState) =>
      //{
      //  var result = _result;
      //  if (result.success)
      //  {
      //    this.loadHtmlTexts();
      //    this.snackBarRef = this.snackBar.open(application.getRawText('pwp.htmltexts.summary.deleted.msg'), application.getRawText('common.close.msg'));
      //  }
      //  else
      //  {
      //    this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      //  }
      //});
    });
  };

  private loadHtmlTexts()
  {
    this.htmlTextsService.GetHtmlTexts(null).subscribe((_result: IHtmlTextExtended[]) =>
    {
      this.htmlTexts = _result;
      this.htmlTextsDataSource = new MatTableDataSource(this.htmlTexts);
    });
  };

  private editHtmlTexts(_htmlText: IHtmlTextExtended)
  {
    this.moduleID = _htmlText.moduleID;
    this.router.navigate(['pwp', 'htmltexts', _htmlText === null ? 0 : _htmlText.moduleID]);
  };

}
