import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import MediaJob from '../../core/models/MediaJob';
import { IMediaJob } from '../../core/interfaces/IMediaJob';
import { IMediaList } from '../../core/interfaces/IMediaList';
import { IMediaDevice } from '../../core/interfaces/IMediaDevice';
import { IWeekDayItem } from '../../../../../app-pwp/src/app/core/interfaces/IWeekDayItem';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../../app-pwp/src/app/core/services/common/datetime.service';
import { WeekDaysService } from '../../../../../app-pwp/src/app/core/services/common/weekdays.service';
import { MediaJobsService } from '../../core/services/data/mediajobs.service';
import { evision5 } from '../../core/globals';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { MediaLayoutsService } from '../../core/services/data/medialayouts.service';
import { MediaDevicesService } from '../../core/services/data/mediadevices.service';
import MediaLayout from '../../core/models/MediaLayout';
import { IMediaLayout } from '../../core/interfaces/IMediaLayout';

@Component({
  selector: 'app-medialayout-single',
  templateUrl: './medialayout-single.component.html',
  styleUrls: ['./medialayout-single.component.css']
})

export class MediaLayoutSingleComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;

  private datePickerConfig: {};

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;

  private mediaLayoutID: string = null;
  private mediaLayout: IMediaLayout = new MediaLayout();
  private mediaDevices: IMediaDevice[] = null;
  private devices: IMediaDevice[] = null;
  private groups: IMediaDevice[] = null;

  private gridSizes = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' }
  ];

  private textDeviceDevice: string = application.getRawText('medialayouts.single.form.device.device.title');
  private textDeviceGroup: string = application.getRawText('medialayouts.single.form.device.group.title');

  private textName: string = application.getRawText('medialayouts.single.form.name.title');
  private textGridSize: string = application.getRawText('medialayouts.single.form.gridsize.title');
  private textColumn1Width: string = application.getRawText('medialayouts.single.form.column1width.title');
  private textColumn2Width: string = application.getRawText('medialayouts.single.form.column2width.title');
  private textRow1Height: string = application.getRawText('medialayouts.single.form.row1height.title');
  private textRow2Height: string = application.getRawText('medialayouts.single.form.row2height.title');
  private textCellPadding: string = application.getRawText('medialayouts.single.form.cellpadding.title');
  private textTransitionMode: string = application.getRawText('medialayouts.single.form.transitionmode.title');
  private textTransitionDuration: string = application.getRawText('medialayouts.single.form.transitionduration.title');
  private textBrowserZoom: string = application.getRawText('medialayouts.single.form.browserzoom.title');
  private textMediaDeviceId01: string = application.getRawText('medialayouts.single.form.mediadeviceid01.title');
  private textMediaDeviceId02: string = application.getRawText('medialayouts.single.form.mediadeviceid02.title');
  private textMediaDeviceId03: string = application.getRawText('medialayouts.single.form.mediadeviceid03.title');
  private textMediaDeviceId04: string = application.getRawText('medialayouts.single.form.mediadeviceid04.title');

  private keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,

    private route: ActivatedRoute,
    private dateTimeService: DateTimeService,
    private uiKeyHandleService: UiKeyHandleService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private mediaLayoutsService: MediaLayoutsService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
    this.keyHandler.OnCancel.subscribe(() => this.triggerOnClose());
  };

  ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }

    this.mediaLayoutID = this.route.snapshot.paramMap.get('id');
    this.initMediaJob();
    this.loadMediaDevices();

    this.initiated = true;
  };

  public init(_mediaLayoutID: string)
  {
    this.mediaLayoutID = _mediaLayoutID;
    this.initMediaJob();
    this.loadMediaDevices();

    this.initiated = true;
  };

  private initMediaJob()
  {
    if (this.mediaLayoutID === 'new')
    {
      this.mediaLayoutID = null;
    }

    if (this.mediaLayoutID === null)
    {
      this.mediaLayout = new MediaLayout();
    }
    else
    {
      this.loadMediaJob();
    }
  };

  private loadMediaJob()
  {
    this.mediaLayoutsService.Load(this.mediaLayoutID).subscribe((_data: IMediaLayout) =>
    {
      this.mediaLayout = _data;
    });
  };

  private loadMediaDevices()
  {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevices/includinggroups'))
      .subscribe(result =>
      {
        this.mediaDevices = result.data as IMediaDevice[];
        this.devices = [];
        this.groups = [];
        for (var i = 0; i < this.mediaDevices.length; i++)
        {
          if (this.mediaDevices[i].deviceGroup)
          {
            this.groups.push(this.mediaDevices[i]);
          }
          else
          {
            this.devices.push(this.mediaDevices[i]);
          }
        }

      }, error => console.error(error));
  };

  private save()
  {
    if (!this.mediaLayout.name)
    {
      this.dialogGeneric = this.dialog.open(DialogComponent, {
        autoFocus: true,
        height: '250px',
        width: '550px'
      });
      this.dialogGeneric.componentInstance.options.actionOk = true;
      this.dialogGeneric.componentInstance.options.title = application.getRawText('common.error.msg');
      this.dialogGeneric.componentInstance.options.message = application.getRawText('medialayouts.single.error.name-missing.msg');
      return;
    }

    this.mediaLayoutsService.Save(this.mediaLayout, this.mediaLayoutID).subscribe((_result: IReturnState) =>
    {
      if (_result.success)
      {
        this.mediaLayout = _result.data as IMediaLayout;
        this.mediaLayoutID = this.mediaLayout.mediaLayoutID;
        this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
        this.triggerOnClose();
      }
      else
      {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  private triggerOnClose()
  {
    this.onClose.emit();
  };
}
