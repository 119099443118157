import { Injectable, Inject } from '@angular/core';
import { application } from 'globals';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { IGlobal } from '../../interfaces/IGlobal';

@Injectable({
  providedIn: 'root',
})

export class HttpWorkerFactoryService
{
  private application: IGlobal = application;

  constructor(private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string)
  {
  }

  public GetWorker(): HttpWorker
  {
    var worker = new HttpWorker(this.http);
    return worker;
  };
}

export class HttpWorker implements ICanBeBusy
{
  constructor(private http: HttpClient) { }

  public IsBusy: IsBusy = new IsBusy();

  public get<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>
  {
    this.IsBusy.Busy();

    var o = new Observable<T>((_observer) =>
    {
      this.http.get<T>(url, options)
        .subscribe((_result) =>
        {
          _observer.next(_result);
          _observer.complete();

          this.IsBusy.Relaxed();
        }, (error) =>
        {
          _observer.complete();
        });
    });
    return o;
  };

  /**
 * Constructs a `POST` request that interprets the body as a JSON object
 * and returns an observable of the response.
 *
 * @param url The endpoint URL.
 * @param body The content to replace with.
 * @param options HTTP options
 *
 * @return  An `Observable` of the `HTTPResponse` for the request, with a response body in the requested type.
 */
  public post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>
  {
    this.IsBusy.Busy();

    var o = new Observable<T>((_observer) =>
    {
      this.http.post<T>(url, body, options)
        .subscribe((_result) =>
        {
          _observer.next(_result);
          _observer.complete();

          this.IsBusy.Relaxed();
        }, (error) =>
        {
          _observer.complete();
        });
    });
    return o;
  };

  /**
 * Constructs a DELETE request that interprets the body as a JSON object and returns
 * the response in a given type.
 *
 * @param url     The endpoint URL.
 * @param options The HTTP options to send with the request.
 *
 * @return An `Observable` of the `HTTPResponse`, with response body in the requested type.
 */
  public delete<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>
  {
    this.IsBusy.Busy();

    var o = new Observable<T>((_observer) =>
    {
      this.http.delete<T>(url, options)
        .subscribe((_result) =>
        {
          _observer.next(_result);
          _observer.complete();

          this.IsBusy.Relaxed();
        }, (error) =>
        {
          _observer.complete();
        });
    });
    return o;
  };

  /**
 * Constructs a `PUT` request that interprets the body as a JSON object
 * and returns an observable of the response.
 *
 * @param url The endpoint URL.
 * @param body The resources to add/update.
 * @param options HTTP options
 *
 * @return An `Observable` of the `HTTPResponse` for the request, with a response body in the requested type.
 */
  put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>
  {
    this.IsBusy.Busy();

    var o = new Observable<T>((_observer) =>
    {
      this.http.post<T>(url, options)
        .subscribe((_result) =>
        {
          _observer.next(_result);
          _observer.complete();

          this.IsBusy.Relaxed();
        }, (error) =>
        {
          _observer.complete();
        });
    });
    return o;
  }
}
