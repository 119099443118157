import { IMediaSettingsCollection } from '../interfaces/IMediaSettingsCollection';

export default class MediaSettingsCollection implements IMediaSettingsCollection
{
  uploadFormatsValues: string[];
  directImageValue: string;
  avoidFullscreenInWindowsMediaValue: boolean;
  videoFormatsValue: string[];
  imageFormatsValue: string[];
  standardUrlValue: string;
  fallbackCultureValue: string;
  browserLanguageDetectionEnabledValue: boolean;
  crossFadingDurationMsValue: number;
  defaultActivityDurationSecValue: number;
  silentVideoPlaybackValue: boolean;
  imageUploadResizeOptionValue: string;
}
