import { Injectable, Inject, EventEmitter } from '@angular/core';
import { application } from 'globals';
import { IGlobal } from '../../interfaces/IGlobal';

@Injectable({
  providedIn: 'root',
})

export class BusyIndicatorFactoryService
{
  private application: IGlobal = application;

  constructor()
  {
  }

  /**
   *  Returns a new busyindicator-worker.
   */
  public GetWorker(): BusyIndicatorWorker
  {
    var worker = new BusyIndicatorWorker();
    return worker;
  };
}

/**
 * Represents an item that can be busy.
 */
export interface ICanBeBusy
{
  IsBusy: IsBusy;
}

export class IsBusy
{
  private children: ICanBeBusy[] = [];

  private value: boolean;
  set Value(_value: boolean) { this.value = _value; this.OnChange.emit(this); }
  get Value(): boolean { return this.value; }

  public readonly OnChange: EventEmitter<IsBusy> = new EventEmitter<IsBusy>();

  public Busy()
  {
    this.Value = true;
  };

  public Relaxed()
  {
    this.Value = false;
  };

  /**
   * Passes the state of a child-element.
   * @param _child
   */
  public PassThrough(_child: ICanBeBusy)
  {
    this.children.push(_child);
    _child.IsBusy.OnChange.subscribe((_isBusy: IsBusy) =>
    {
      this.Value = _isBusy.Value;
    });
  };
}

/**
 *  Manages several busy-inputs to one output.
 */
export class BusyIndicatorWorker
{
  constructor() { }

  private itemsThatCanBeBusy: ICanBeBusy[] = [];

  public IsBusy: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Registers an item, that can be busy.
   * @param _iCanBeBusy
   */
  public Register(_iCanBeBusy: ICanBeBusy)
  {
    this.itemsThatCanBeBusy.push(_iCanBeBusy);

    _iCanBeBusy.IsBusy.OnChange.subscribe((_isBusy) =>
    {
      this.CheckOutput();
    });
  }


  private CheckOutput()
  {
    var isBusy = false;
    for (var i = 0; i < this.itemsThatCanBeBusy.length; i++)
    {
      var item = this.itemsThatCanBeBusy[i];
      if (item.IsBusy.Value)
      {
        isBusy = true;
        break;
      }
    }

    this.IsBusy.emit(isBusy);
  };
}
