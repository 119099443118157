import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Component, NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from '@tinymce/tinymce-angular';

import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeEn);
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

import * as hljs from 'highlight.js';
import { HighlightJsModule, HIGHLIGHT_JS } from 'angular-highlight-js';
export function highlightJsFactory()
{
  return hljs;
}

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppComponent } from './app.component';

import { LayoutModule } from '@angular/cdk/layout';
import { from } from 'rxjs';

// Apps
import { AppPwpEditorAddImage } from '../../projects/app-pwp-editor-add-image/src/app/app.module';
import { AppPwp } from '../../projects/app-pwp/src/app/app.module';
import { AppEvision5 } from '../../projects/app-pwp-evision5/src/app/app.module';

// E5
import { DashboardComponent } from '../../projects/app-pwp-evision5/src/app/dashboard/dashboard.component';
import { MediaListsSummaryComponent } from '../../projects/app-pwp-evision5/src/app/medialists-summary/medialists-summary.component';
import { MediaActivitiesSummaryComponent } from '../../projects/app-pwp-evision5/src/app/mediaactivities-summary/mediaactivities-summary.component';
import { MediaListSingleComponent } from '../../projects/app-pwp-evision5/src/app/medialist-single/medialist-single.component';
import { MediaActivitySingleComponent } from '../../projects/app-pwp-evision5/src/app/mediaactivity-single/mediaactivity-single.component';
import { MediaDevicesSummaryComponent } from '../../projects/app-pwp-evision5/src/app/mediadevices-summary/mediadevices-summary.component';
import { SceneJobsSummaryComponent } from '../../projects/app-pwp-evision5/src/app/scenes/scene-jobs-summary/scene-jobs-summary.component';
import { ScenesSummaryComponent } from '../../projects/app-pwp-evision5/src/app/scenes/scenes-summary/scenes-summary.component';
import { SettingsComponent } from '../../projects/app-pwp-evision5/src/app/settings/settings.component';
import { UserSettingsComponent } from '../../projects/app-pwp-evision5/src/app/usersettings/usersettings.component';
import { MediaDeviceSingleComponent } from '../../projects/app-pwp-evision5/src/app/mediadevice-single/mediadevice-single.component';
import { MediaDeviceStartupExceptionSummaryComponent } from '../../projects/app-pwp-evision5/src/app/mediadevicestartupexception-summary/mediadevicestartupexception-summary.component';
import { MediaJobsSummaryComponent } from '../../projects/app-pwp-evision5/src/app/mediajobs-summary/mediajobs-summary.component';
import { MediaJobSingleComponent } from '../../projects/app-pwp-evision5/src/app/mediajob-single/mediajob-single.component';
import { MediaActivitiesDurationComponent } from '../../projects/app-pwp-evision5/src/app/mediaactivities-duration/mediaactivities-duration.component';
import { MediaDevicesStartuptimeComponent } from '../../projects/app-pwp-evision5/src/app/mediadevices-startuptime/mediadevices-startuptime.component';
import { MediaDeviceStartupExceptionSingleComponent } from '../../projects/app-pwp-evision5/src/app/mediadevicestartupexception-single/mediadevicestartupexception-single.component';
import { MediaListSetLinkedListsComponent } from '../../projects/app-pwp-evision5/src/app/medialist-setlinklists/medialist-setlinklists.component';
import { FileExplorerComponent } from '../../projects/app-pwp-evision5/src/app/fileexplorer/fileexplorer.component';
import { WebPresenterComponent } from '../../projects/app-pwp-evision5/src/app/webpresenter/webpresenter.component';
import { EditDirectoryComponent } from '../../projects/app-pwp-evision5/src/app/fileexplorer/editdirectory/editdirectory.component';
import { SetPasswordComponent } from '../../projects/app-pwp-evision5/src/app/usersettings/setpassword/setpassword.component';
import { SceneSingleComponent } from '../../projects/app-pwp-evision5/src/app/scenes/scene-single/scene-single.component';
import { SceneJobsEditSummaryComponent } from '../../projects/app-pwp-evision5/src/app/scenes/scene-jobs-edit-summary/scene-jobs-edit-summary.component';

// PWP
import { HtmlTextsSummaryComponent } from '../../projects/app-pwp/src/app/htmltexts-summary/htmltexts-summary.component';
import { HtmlTextSingleComponent } from '../../projects/app-pwp/src/app/htmltext-single/htmltext-single.component';
import { EventItemsSummaryComponent } from '../../projects/app-pwp/src/app/eventitems-summary/eventitems-summary.component';
import { EventItemSingleComponent } from '../../projects/app-pwp/src/app/eventitem-single/eventitem-single.component';
import { UsersSummaryComponent } from '../../projects/app-pwp/src/app/users-summary/users-summary.component';
import { RolesSummaryComponent } from '../../projects/app-pwp/src/app/roles-summary/roles-summary.component';
import { RoleSingleComponent } from '../../projects/app-pwp/src/app/role-single/role-single.component';
import { UserSingleComponent } from '../../projects/app-pwp/src/app/user-single/user-single.component';
import { ImageSelectorComponent } from '../../projects/app-pwp/src/app/image-selector/image-selector.component';
import { ApiKeySingleComponent } from '../../projects/app-pwp/src/app/apikey-single/apikey-single.component';
import { LocaleService } from '../../projects/app-pwp/src/app/core/services/common/locale.service';
import { AuthInterceptor } from '../../projects/app-pwp/src/app/authinterceptor';
import { DialogComponent } from '../../projects/app-pwp/src/app/dialog/dialog.component';
import { ApiKeysSummaryComponent } from '../../projects/app-pwp/src/app/apikeys-summary/apikeys-summary.component';
import { ImageEditorComponent } from '../../projects/app-pwp-editor-add-image/src/app/imageeditor/imageeditor.component';
import { AppE5Component } from '../../projects/app-pwp-evision5/src/app/app-e5.component';
import { AppImgEditComponent } from '../../projects/app-pwp-editor-add-image/src/app/app-img-edit.component';
import { ArticlesSummaryComponent } from '../../projects/app-pwp/src/app/articles-summary/articles-summary.component';
import { ArticleSingleComponent } from '../../projects/app-pwp/src/app/article-single/article-single.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { SlackOauthHandlerSingleComponent } from '../../projects/app-pwp/src/app/slack/slack-oauth-handler/slack-oauth-handler.component';
import { MediaLayoutsSummaryComponent } from '../../projects/app-pwp-evision5/src/app/medialayouts/medialayouts-summary/medialayouts-summary.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    EditorModule,
    FontAwesomeModule,

    AppPwp.forRoot(),
    AppPwpEditorAddImage.forRoot(),
    AppEvision5.forRoot(),

    HighlightJsModule.forRoot({
      provide: HIGHLIGHT_JS,
      useFactory: highlightJsFactory
    }),

    RouterModule.forRoot([

      // PWP / ImageEditor
      {
        path: 'pwp/htmltext/imageeditor', component: AppImgEditComponent, children: [
          { path: '', component: ImageEditorComponent }
        ]
      },

      // EVISION5
      {
        path: '', component: AppE5Component, children: [
          { path: '', component: DashboardComponent, pathMatch: 'full' },

          { path: 'medialibrary', component: FileExplorerComponent },
          { path: 'medialists', component: MediaListsSummaryComponent },
          { path: 'medialists/new', component: MediaListSingleComponent },
          { path: 'medialists/:id', component: MediaListSingleComponent },
          { path: 'medialists/:id/mediaactivities', component: MediaActivitiesSummaryComponent },
          { path: 'medialists/:id/mediaactivities/:idActivity', component: MediaActivitySingleComponent },
          { path: 'medialists/:id/mediaactivities/new', component: MediaActivitySingleComponent },

          { path: 'mediadevices', component: MediaDevicesSummaryComponent },
          { path: 'mediadevices/group/:groupdID', component: MediaDevicesSummaryComponent },

          { path: 'medialayouts', component: MediaLayoutsSummaryComponent },

          { path: 'scenes', component: ScenesSummaryComponent },
          { path: 'scenes/:sceneID', component: SceneJobsSummaryComponent },

          { path: 'settings', component: SettingsComponent },
          { path: 'account', component: UserSettingsComponent },
          { path: 'mediadevices/:idMediaDevice', component: MediaDeviceSingleComponent },

          { path: 'mediadevices/startup/exceptions', component: MediaDeviceStartupExceptionSummaryComponent },

          { path: 'mediaactivities/:idActivity', component: MediaActivitySingleComponent },

          { path: 'mediajobs', component: MediaJobsSummaryComponent },
          { path: 'mediajobs/:id', component: MediaJobSingleComponent },

          { path: 'pwp/articles', component: ArticlesSummaryComponent },
          { path: 'pwp/articles/:id', component: ArticleSingleComponent },
          { path: 'pwp/htmltexts', component: HtmlTextsSummaryComponent },
          { path: 'pwp/htmltexts/:id', component: HtmlTextSingleComponent },
          { path: 'pwp/eventitems', component: EventItemsSummaryComponent },
          { path: 'pwp/users', component: UsersSummaryComponent },
          { path: 'pwp/roles', component: RolesSummaryComponent },

          { path: 'system/slackhandler/:id', component: SlackOauthHandlerSingleComponent }
        ]
      }


      //{ path: 'webpresenter/:deviceID', component: WebPresenterComponent },

    ]),
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    MatSliderModule,
    MatExpansionModule,
    MatIconModule,
    NgxFileDropModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    MatBadgeModule,
    MatSortModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    LayoutModule,
    FontAwesomeModule
    //AngularFontAwesomeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 4000 } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {

          dateInput: 'DD.MM.YYYY',
        },
        display: {

          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM-YYYY',
        }
      }
    },
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) =>
      {
        var language = localeService.getLanguage();
        console.log('locale ID', language);
        return language;
      },
      deps: [LocaleService]
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MediaActivitiesDurationComponent,
    MediaDevicesStartuptimeComponent,
    MediaDeviceStartupExceptionSingleComponent,
    MediaListSetLinkedListsComponent,
    FileExplorerComponent,
    EditDirectoryComponent,
    SetPasswordComponent,
    SceneSingleComponent,
    SceneJobsEditSummaryComponent,
    DialogComponent,
    EventItemSingleComponent,
    UserSingleComponent,
    ImageSelectorComponent,
    ApiKeySingleComponent,
    RoleSingleComponent
  ]
})
export class AppModule { }
