import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { application } from '../../core/globals';
import { IApiAccessSlack } from '../../core/interfaces/IApiAccessSlack';
import { IGlobal } from '../../core/interfaces/IGlobal';
import { IGlobalSettings } from '../../core/interfaces/IGlobalSettings';
import { IReturnState } from '../../core/interfaces/IReturnState';
import ApiAccessSlack from '../../core/models/ApiAccessSlack';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from '../../core/services/busyindicator/busyindicatorfactory.service';
import { SecurityService } from '../../core/services/common/security.service';
import { KeyHandler, UiKeyHandleService } from '../../core/services/common/uikeyhandle.service';
import { UriBuilderService } from '../../core/services/common/uribuilder.service';
import { UtilsService } from '../../core/services/common/utils.service';
import { GlobalSettingsService } from '../../core/services/data/globalsettings.service';
import { LoggerService } from '../../core/services/data/logger.service.ts';
import { SlackService } from '../../core/services/data/slack.service';
import { HttpWorker, HttpWorkerFactoryService } from '../../core/services/http/httpworkerfactory.service';

@Component({
  selector: 'app-slack-oauth-handler',
  templateUrl: './slack-oauth-handler.component.html',
  styleUrls: ['./slack-oauth-handler.component.css']
})

export class SlackOauthHandlerSingleComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  private application: IGlobal = application;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public slackId: number = null;
  private item: IApiAccessSlack = new ApiAccessSlack();

  private globalSettings: IGlobalSettings;

  private success: boolean = false;
  private error: boolean = false;
  private inProcess: boolean = true;
  private errorMsg: string = '';

  private textName: string = application.getRawText('pwp.slack.single.form.name.label');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  private keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private utilsService: UtilsService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private slackService: SlackService,
    private uriBuilderService: UriBuilderService,
    private globalSettingsService: GlobalSettingsService,
    private loggerService: LoggerService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.slackService);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    //this.keyHandler.OnSave.subscribe(() => this.save());
  };

  private ngOnInit()
  {
    this.globalSettingsService.GetSettings().subscribe((_settings) =>
    {
      this.globalSettings = _settings;
      this.getSlackApi();
    });
  };

  /**
   * Gets the slackApi and triggers token-exchange.
   */
  private getSlackApi()
  {
    let tmpID = parseInt(this.route.snapshot.paramMap.get('id'));
    let url = new URLSearchParams(window.location.search);
    let slackCode = url.get('code');

    // First get slack-item from db.
    this.slackService.GetApiKey(tmpID).subscribe((_slack: IReturnState) =>
    {
      let slack = _slack.data as IApiAccessSlack;
      if (slack !== null)
      {
        this.loggerService.Debug(`Callback by slack. Start code/bearertoken-exchange for ApiAccessSlack [${tmpID}].`);

        this.exchangeBearerToken(slack, slackCode);
      }
      else
      {
        this.loggerService.Error(`Callback by slack. ApiAccessSlack-id [${tmpID}] is invalid, object not found.`);

        this.inProcess = false;
        this.success = false;
        this.errorMsg = `There is no slack-item with id [${tmpID}].`;
      }
    });
  };

  /**
   * Uses the authorization code to get the bearer token.
   * @param _slack
   * @param _slackCode
   */
  private exchangeBearerToken(_slack: IApiAccessSlack, _slackCode: string)
  {
    let appUrl = this.utilsService.GetAppUrl();

    var uriBuilderSlackHandler = this.uriBuilderService.Get()
      .Parse(appUrl)
      .AddSegment('system')
      .AddSegment('slackhandler')
      .AddSegment(_slack.id.toString());
    let redirectUri = uriBuilderSlackHandler.Build();

    this.loggerService.Trace(`App-url [${appUrl}] redirect-uri [${redirectUri}].`);

    var uriBuilder = this.uriBuilderService.Get()
      .Parse(this.globalSettings.apiAccessSlackAccess)
      .AddQueryString('code', _slackCode)
      .AddQueryString('client_id', _slack.clientID)
      .AddQueryString('client_secret', _slack.clientSecret)
      .AddQueryString('redirect_uri', redirectUri);

    this.loggerService.Debug(`Start bearer-token-exchange using [${this.globalSettings.apiAccessSlackAccess}].`);

    let accessUri = uriBuilder.Build();
    this.http.post(accessUri, null).subscribe((_result: any) =>
    {
      let url = new URLSearchParams(window.location.search);
      let returnedState = url.get('state');

      // Validate state-variable first. Security-check against attacks.
      if (_slack.state !== returnedState)
      {
        this.loggerService.Error(`An error has occurred. Passed state [${_slack.state}] is different to returned state [${returnedState}]. Cancel. Indicator for security issue.`)

        this.inProcess = false;
        this.success = false;
        this.errorMsg = 'A security-issue has occurred. Please contact your administrator.';
      }
      else if (_result.ok === true)
      {
        this.loggerService.Info(`Getting token for ApiAccessSlack [${_slack.id}] was successful. Writing data to database.`);

        // Update information on database
        _slack.accessToken = _result.access_token;
        _slack.userAccessToken = _result.authed_user.id;
      }
      else
      {
        this.loggerService.Error(`An error has occurred. Error returned by slack [${_result.error}].`)

        this.inProcess = false;
        this.success = false;
        this.errorMsg = _result.error;
      }

      this.updateSlackItem(_slack, _result.ok);
    });
  };

  /**
   * Updates the slackitem on the database.
   * @param _slack
   */
  private updateSlackItem(_slack: IApiAccessSlack, _state: boolean)
  {
    this.slackService.ConfigureSuccess(_slack, _state).subscribe((_result) =>
    {
      this.inProcess = false;
      this.success = !(this.errorMsg !== null && this.errorMsg.length > 0);
    });
  };
}
