import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import MediaJob from '../../core/models/MediaJob';
import { IMediaJob } from '../../core/interfaces/IMediaJob';
import { IMediaList } from '../../core/interfaces/IMediaList';
import { IMediaDevice } from '../../core/interfaces/IMediaDevice';
import { IWeekDayItem } from '../../../../../app-pwp/src/app/core/interfaces/IWeekDayItem';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../../app-pwp/src/app/core/services/common/datetime.service';
import { WeekDaysService } from '../../../../../app-pwp/src/app/core/services/common/weekdays.service';
import { MediaJobsService } from '../../core/services/data/mediajobs.service';
import { evision5 } from '../../core/globals';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';

@Component({
  selector: 'app-scene-single',
  templateUrl: './scene-single.component.html',
  styleUrls: ['./scene-single.component.css']
})

export class SceneSingleComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  private dateTimePickerSettings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy hh:mm',
    defaultOpen: false
  };

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;

  private datePickerConfig: {};

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;

  private mediaJobID: string = null;
  private scene: IMediaJob = new MediaJob();
  private mediaLists: IMediaList[] = null;
  private mediaDevices: IMediaDevice[] = null;
  private devices: IMediaDevice[] = null;
  private groups: IMediaDevice[] = null;

  private startTime: string = '00:00';
  private endTime: string = '00:00';

  private days: IWeekDayItem[] = this.weekDaysService.GetWeekDays();

  private textName: string = application.getRawText('scene.single.form.name.title');
  private textLooped: string = application.getRawText('scene.single.form.looped.title');
  private textStart: string = application.getRawText('scene.single.form.start.title');
  private textEnd: string = application.getRawText('scene.single.form.end.title');
  private textLoopCount: string = application.getRawText('scene.single.form.loopcount.title');

  private keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,

    private route: ActivatedRoute,
    private dateTimeService: DateTimeService,
    private weekDaysService: WeekDaysService,
    private uiKeyHandleService: UiKeyHandleService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private mediaJobsService: MediaJobsService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
    this.keyHandler.OnCancel.subscribe(() => this.triggerOnClose());
  };

  ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }

    this.mediaJobID = this.route.snapshot.paramMap.get('id');
    this.initMediaJob();
    this.loadMediaLists();
    this.loadMediaDevices();

    this.initiated = true;
  };

  public init(_mediaJobID: string)
  {
    this.mediaJobID = _mediaJobID;
    this.initMediaJob();
    this.loadMediaLists();
    this.loadMediaDevices();

    this.initiated = true;
  };

  private initMediaJob()
  {
    if (this.mediaJobID === 'new')
    {
      this.mediaJobID = null;
    }

    if (this.mediaJobID === null)
    {
      this.scene = new MediaJob();
      this.scene.isScene = true;
      this.scene.startTime = this.dateTimeService.Now();
      this.scene.endTime = null;
      this.scene.looped = false;
      this.scene.loopCount = -1;

      this.startTime = this.dateTimeService.GetTime(this.scene.startTime, true);
      this.endTime = this.dateTimeService.GetTime(this.scene.endTime, true);
      if (this.endTime === null)
      {
        this.endTime = '00:00';
      }
    }
    else
    {
      this.loadMediaJob();
    }
  };

  private loadMediaJob()
  {
    this.mediaJobsService.GetScene(this.mediaJobID).subscribe((_data: IMediaJob) =>
    {
      this.setMediaJob(_data);
    });
  };

  private setMediaJob(_mediaJob: IMediaJob)
  {
    this.scene = _mediaJob;
    this.getReplayDaysFromModel();

    var tmpStart = this.dateTimeService.EnsureUTC(this.scene.startTime);
    this.startTime = this.dateTimeService.GetTime(tmpStart, true);
    this.scene.startTime = this.dateTimeService.TimeToLunch(tmpStart);

    if (this.scene.endTime !== null)
    {
      this.scene.endTime = this.dateTimeService.EnsureUTC(this.scene.endTime);

      if (this.scene.endTime === this.dateTimeService.MaxDate())
      {
        this.scene.endTime = null;
        this.endTime = '00:00';
      }
      else
      {
        this.endTime = this.dateTimeService.GetTime(this.scene.endTime, true);
        this.scene.endTime = this.dateTimeService.TimeToLunch(this.scene.endTime);
      }
    }
  };

  private loadMediaLists()
  {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialists'))
      .subscribe(result =>
      {
        this.mediaLists = result.data as IMediaList[];
      }, error => console.error(error));
  };

  private loadMediaDevices()
  {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevices/includinggroups'))
      .subscribe(result =>
      {
        this.mediaDevices = result.data as IMediaDevice[];
        this.devices = [];
        this.groups = [];
        for (var i = 0; i < this.mediaDevices.length; i++)
        {
          if (this.mediaDevices[i].deviceGroup)
          {
            this.groups.push(this.mediaDevices[i]);
          }
          else
          {
            this.devices.push(this.mediaDevices[i]);
          }
        }

      }, error => console.error(error));
  };

  private loopedChangeHandler()
  {
    if (this.scene.looped === false)
    {
      this.scene.loopCount = -1;
      this.scene.endTime = null;
      this.endTime = '00:00';
    }
    else
    {
      this.scene.loopCount = 1;
      this.scene.endTime = null;
      this.endTime = '00:00';
    }
  };

  private setReplayDaysToModel()
  {
    this.scene.replayDays = this.weekDaysService.ConvertStartupDaysToValue(this.days);
  };

  private getReplayDaysFromModel()
  {
    this.weekDaysService.ConvertStartupDaysFromValue(this.days, this.scene.replayDays);
  };

  private startFromNow()
  {
    var tmp = this.dateTimeService.Now();
    this.startTime = this.dateTimeService.GetTime(tmp, true);
    this.scene.startTime = this.dateTimeService.TimeToLunch(tmp);
  };

  private endToNow()
  {
    var tmp = this.dateTimeService.Now();
    this.endTime = this.dateTimeService.GetTime(tmp, true);
    this.scene.endTime = this.dateTimeService.TimeToLunch(tmp);
  };

  private save()
  {
    this.setReplayDaysToModel();

    if (!this.scene.jobName)
    {
      this.dialogGeneric = this.dialog.open(DialogComponent, {
        autoFocus: true,
        height: '250px',
        width: '550px'
      });
      this.dialogGeneric.componentInstance.options.actionOk = true;
      this.dialogGeneric.componentInstance.options.title = application.getRawText('common.error.msg');
      this.dialogGeneric.componentInstance.options.message = application.getRawText('scene.single.save.error.missing-name.msg');
      return;
    }

    var start = this.dateTimeService.SetTimeToDate(this.startTime, this.scene.startTime);
    var end = this.scene.endTime;
    if (this.scene.looped === false)
    {
      if (end === null)
      {
        end = this.dateTimeService.MaxDate();
      }
      else
      {
        end = this.dateTimeService.SetTimeToDate(this.endTime, this.scene.endTime);
      }
    }

    if (this.scene.looped === true)
    {
      end = this.dateTimeService.MaxDate();
    }
    else
    {
      this.scene.loopCount = 1;
      // Check dates
      if (new Date(start) >= new Date(end))
      {
        this.dialogGeneric = this.dialog.open(DialogComponent, {
          autoFocus: true,
          height: '250px',
          width: '550px'
        });
        this.dialogGeneric.componentInstance.options.actionOk = true;
        this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
        this.dialogGeneric.componentInstance.options.message = application.getRawText('mediajobs.summary.error.startdate-greater-enddate.msg');
        return;
      }
    }

    this.scene.startTime = start;
    this.scene.endTime = end;

    this.mediaJobsService.SaveScene(this.scene, this.mediaJobID).subscribe((_result: IReturnState) =>
    {
      if (_result.success)
      {
        var tmp = _result.data as IMediaJob;
        this.setMediaJob(tmp);

        this.mediaJobID = this.scene.mediaJobID;
        this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
        this.triggerOnClose();
      }
      else
      {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  private triggerOnClose()
  {
    this.onClose.emit();
  };
}
