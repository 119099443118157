import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { retry } from 'rxjs/internal/operators';
import { IGlobal } from '../../interfaces/IGlobal';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class UriBuilderService
{
  private application: IGlobal = application;

  constructor(private router: Router) { }

  /**
   *  Returns a new uriBuilder.
   **/
  public Get(): UriBuilder
  {
    return new UriBuilder();
  }
}

export class UriBuilder
{
  private seperator: string = '/';
  get Seperator(): string { return this.seperator; }

  private protocol: string = null;
  get Protocol(): string { return this.protocol; }

  private host: string = null;
  get Host(): string { return this.host; }

  private port: number = null;
  get Port(): number { return this.port; }

  private segments: UriSegment[] = [];
  get Segments(): UriSegment[] { return this.segments; }

  private queryStringsList: QueryString[] = [];
  private queryStrings: object = {};
  get QueryStrings(): object { return this.queryStrings; }

  /**
   * Sets this uri as web-uri.
   */
  public AsWeb(): UriBuilder
  {
    this.seperator = '/';
    return this;
  }

  /**
   * Sets this uri as path.
   */
  public AsPath(): UriBuilder
  {
    this.seperator = '\\';
    return this;
  }

  /**
   * Parses a given uri.
   * @param _uri
   */
  public Parse(_uri: string): UriBuilder
  {
    if (_uri === null || _uri === '')
    {
      return this;
    }

    var tmp = _uri;
    var protocolCheck = tmp.toLowerCase();

    // Protocol
    var protocols = ["http:", "https:", "file:", "ftp:", "ftps:"];
    for (var p = 0; p < protocols.length; p++)
    {
      var prot = protocols[p];
      if (protocolCheck.indexOf(prot) === 0)
      {
        this.protocol = prot.substr(0, prot.length - 1);
        tmp = tmp.substr(prot.length);
        break;
      }
    }

    // Use here _uri since protocol could contain seperator.
    this.seperator = _uri.indexOf("/") >= 0 ? '/' : '\\';

    // Check if there is a host.
    // Host and port is only available if this is not a relative path.
    // Otherwise is host empty. Check for starting single / or missing /
    var hasHost = tmp.indexOf(this.seperator + this.seperator) === 0;
    if (hasHost == false || ((tmp.indexOf(this.seperator) >= 0) == false && tmp.length > 1 && tmp[1] != this.seperator))
    {
      var idxOfSep = tmp.indexOf(this.seperator);
      var tmpTillSegment = idxOfSep >= 0
        ? tmp.substring(0, idxOfSep)
        : tmp;

      hasHost = tmpTillSegment.match(/^([a-zA-Z0-9]*[.]){0,1}([a-zA-Z0-9-]*)([.]{1}[a-zA-Z0-9]*)$/ig).length > 0;
    }

    // Get host
    if (hasHost)
    {
      while (tmp.indexOf("/") === 0)
      {
        tmp = tmp.substr(1);
      }

      // Host
      var idxAfterHost = tmp.indexOf(":") > 0
        ? tmp.indexOf(":")
        : tmp.indexOf(this.seperator);

      // Check for cases like ... //my.url.com
      this.host = idxAfterHost >= 0
        ? tmp.substr(0, idxAfterHost)
        : tmp.substr(0);
      tmp = tmp.substr(this.host.length);

      // Port
      if (tmp.indexOf(":") === 0)
      {
        var idxOfSeperatorAfterPort = tmp.indexOf("/");
        if (idxOfSeperatorAfterPort < 0)
        {
          var tmpPort = parseInt(tmp.substr(1));
          if (tmpPort === tmpPort)
          {
            this.port = tmpPort;
          }
        }
        else
        {
          var tmpPort = parseInt(tmp.substr(1, idxOfSeperatorAfterPort - 1));
          if (tmpPort === tmpPort)
          {
            // NaN check passed. (NaN === NaN) => false
            this.port = tmpPort;
          }
        }

        // Skip port in uri
        if (this.port !== null)
        {
          tmp = tmp.substr(this.port.toString().length + 1);
        }
      }
    }

    // QueryString
    var idxQueryString = tmp.indexOf('?');
    if (idxQueryString >= 0)
    {
      var queryString = tmp.substr(idxQueryString + 1);
      tmp = tmp.substr(0, idxQueryString);

      var queryStrings = queryString.split('&');
      for (var q = 0; q < queryStrings.length; q++)
      {
        var qs = queryStrings[q];
        var valuePair = qs.split('=');
        if (valuePair.length > 0)
        {
          var queryStringItem = new QueryString();
          queryStringItem.Key = valuePair[0];
          this.queryStringsList.push(queryStringItem);
          this.queryStrings[valuePair[0]] = '';
          if (valuePair.length > 1)
          {
            queryStringItem.Value = valuePair[1];
            this.queryStrings[valuePair[0]] = valuePair[1];
          }
        }
      }
    }

    // Segments
    var tmpSegments = tmp.split(this.seperator);
    for (var s = 0; s < tmpSegments.length; s++)
    {
      this.AddSegment(tmpSegments[s]);
    }

    // Done
    return this;
  };

  /**
   * Adds a segment to this uri.
   * @param _value
   */
  public AddSegment(_value: string): UriBuilder
  {
    if (_value === undefined || _value === null || _value === '')
    {
      return this;
    }

    var splitter = _value.indexOf('\\') >= 0
      ? '\\'
      : '/';

    var tmpSegments = _value.split(splitter);
    for (var s = 0; s < tmpSegments.length; s++)
    {
      var tmp = tmpSegments[s];
      while (tmp.indexOf("/") === 0)
      {
        tmp = tmp.substr(1);
      }

      while (tmp.indexOf("\\") === 0)
      {
        tmp = tmp.substr(1);
      }

      if (tmp === null || tmp === '')
      {
        continue;
      }

      var uriSegment = new UriSegment();
      uriSegment.Value = tmp;
      uriSegment.UriBuilder = this;
      this.segments.push(uriSegment);
    }

    return this;
  };

  /**
   * Clears all segments.
   */
  public ClearSegments(): UriBuilder
  {
    while (this.segments.length > 0)
    {
      this.segments.shift();
    }

    return this;
  };

  /**
   * Builds the uri.
   */
  public Build(): string
  {
    var uri = '';

    if (this.protocol !== null && this.protocol !== '')
    {
      uri = this.protocol + ':';
    }

    if (this.host !== null && this.host !== '')
    {
      if (this.port !== null)
      {
        uri = uri + '//' + this.host + ':' + this.port.toString();
      }
      else
      {
        uri = uri + '//' + this.host;
      }
    }

    for (var i = 0; i < this.segments.length; i++)
    {
      uri = uri + this.seperator + this.segments[i].Clean();
    }

    if (this.queryStringsList.length > 0)
    {
      uri = uri + '?';
    }

    for (var q = 0; q < this.queryStringsList.length; q++)
    {
      var qs = this.queryStringsList[q];
      uri = uri + qs.Key + '=';
      if (qs.HasValue())
      {
        uri = uri + encodeURIComponent(qs.Value);
      }

      if (q < this.queryStringsList.length - 1)
      {
        uri = uri + '&';
      }
    }

    return uri;
  };

  /**
   * Sets the host.
   * @param _host
   */
  public UseHost(_host: string): UriBuilder
  {
    this.host = _host;
    return this;
  };

  /**
   * Adds or sets a queryString.
   * @param _key
   * @param _value
   */
  public AddQueryString(_key: string, _value: string): UriBuilder
  {
    this.queryStrings[_key] = _value;

    var exists = false;
    for (var i = 0; i < this.queryStringsList.length; i++)
    {
      if (this.queryStringsList[i].Key === _key)
      {
        this.queryStringsList[i].Value = _value;
        exists = true;
        break;
      }
    }

    if (exists === false)
    {
      var qs = new QueryString();
      qs.Key = _key;
      qs.Value = _value;
      this.queryStringsList.push(qs);
    }

    return this;
  };

  /**
   * Removes the queryString with the given key.
   * @param _key
   */
  public RemoveQueryString(_key: string): UriBuilder
  {
    delete this.queryStrings[_key];

    var tmp = [];
    for (var i = 0; i < this.queryStringsList.length; i++)
    {
      if (this.queryStringsList[i].Key === _key)
      {
        continue;
      }

      tmp.push(this.queryStringsList[i]);
    }
    this.queryStringsList = tmp;

    return this;
  }
};

/**
 * Represents a queryString-element.
 */
export class QueryString
{
  constructor(_key: string = null, _value: any = null)
  {
    if (_key != null)
    {
      this.Key = _key;
    }

    if (_value != null)
    {
      this.Value = _value.toString();
    }
  }

  private value: string;
  get Value(): string { return this.value; }
  set Value(_value: string) { this.value = _value; }

  private key: string;
  get Key(): string { return this.key; }
  set Key(_key: string) { this.key = _key; }

  public HasValue(): boolean
  {
    return this.key !== null && this.key !== '';
  };
};

export class UriSegment
{
  private uriBuilder: UriBuilder;
  get UriBuilder(): UriBuilder { return this.uriBuilder; }
  set UriBuilder(_uriBuilder: UriBuilder) { this.uriBuilder = _uriBuilder; }

  private value: string;
  get Value(): string { return this.value; }
  set Value(_value: string) { this.value = _value; }

  /**
   * Returns this string cleaned.
   **/
  public Clean(): string
  {
    if (this.value === null || this.value === '')
    {
      return '';
    }

    var tmp = this.value;
    while (tmp.length > 0 && tmp.charAt(0) === this.uriBuilder.Seperator)
    {
      tmp = tmp.substr(1);
    }

    while (tmp.length > 0 && tmp.charAt(tmp.length - 1) === this.uriBuilder.Seperator)
    {
      tmp = tmp.substr(0, tmp.length - 1);
    }

    return tmp;

  };
};
