import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { IMediaDevice } from '../core/interfaces/IMediaDevice';
import MediaDevice from '../core/models/MediaDevice';
import { IMediaDeviceRoleSelectable } from '../core/interfaces/IMediaDeviceRoleSelectable';
import { IMediaDeviceSelectable } from '../core/interfaces/IMediaDeviceSelectable';
import { IWeekDayItem } from '../../../../app-pwp/src/app/core/interfaces/IWeekDayItem';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { WeekDaysService } from '../../../../app-pwp/src/app/core/services/common/weekdays.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { UtilsService } from '../../../../app-pwp/src/app/core/services/common/utils.service';
import { RolesService } from '../../../../app-pwp/src/app/core/services/data/roles.service';
import { MediaDevicesService } from '../core/services/data/mediadevices.service';
import { MediaDeviceRoleSelectable } from '../core/models/MediaDeviceRoleSelectable';
import { IRole } from '../../../../app-pwp/src/app/core/interfaces/IRole';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IGlobal } from '../../../../app-pwp/src/app/core/interfaces/IGlobal';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';

@Component({
  selector: 'app-mediadevice-single',
  templateUrl: './mediadevice-single.component.html',
  styleUrls: ['./mediadevice-single.component.css']
})

export class MediaDeviceSingleComponent
{
  private application: IGlobal = application;

  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;

  public onClose = new EventEmitter();

  public mediaDeviceGroupID: string = null;
  public mediaDeviceID: string = null;
  private mediaDevice: IMediaDevice = new MediaDevice();

  private roles: IMediaDeviceRoleSelectable[] = [];

  private startTime: string = '00:00';
  private endTime: string = '00:00';

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;

  // device, group
  private mode: string = 'device';

  private mediaDevices: IMediaDeviceSelectable[] = null;

  private days: IWeekDayItem[] = this.weekDays.GetWeekDays()

  private textDeviceName: string = application.getRawText('mediadevice.single.form.devicename.title');
  private textGroupName: string = application.getRawText('mediadevice.single.form.groupname.title');
  private textMachineName: string = application.getRawText('mediadevice.single.form.machinename.title');
  private textDeviceIP: string = application.getRawText('mediadevice.single.form.deviceip.title');
  private textDeviceMAC: string = application.getRawText('mediadevice.single.form.devicemac.title');

  private textDescription: string = application.getRawText('mediadevice.single.form.description.title');
  private textDefaultUrl: string = application.getRawText('mediadevice.single.form.defaulturl.title');
  private textLocation: string = application.getRawText('mediadevice.single.form.location.title');

  private textSilentVideoPlayback: string = application.getRawText('mediadevice.single.form.silentvideoplayback.title');
  private textDisplaySerialNumber: string = application.getRawText('mediadevice.single.form.displayserialnumber.title');
  private textPcSerialNumber: string = application.getRawText('mediadevice.single.form.pcserialnumber.title');
  private textDisplayTypeName: string = application.getRawText('mediadevice.single.form.displaytypename.title');
  private textDisplayWaranty: string = application.getRawText('mediadevice.single.form.displaywaranty.title');
  private textPcWaranty: string = application.getRawText('mediadevice.single.form.pcwaranty.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  private keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private weekDays: WeekDaysService,
    private dateTime: DateTimeService,
    private route: ActivatedRoute,
    private uiKeyHandleService: UiKeyHandleService,
    private dateTimeService: DateTimeService,
    private utilsService: UtilsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private rolesService: RolesService,
    private mediaDevicesService: MediaDevicesService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    //this.security.checkForRolesByName('devices.summary');
    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
    this.keyHandler.OnCancel.subscribe(() => this.close());
  };

  private ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }

    this.mediaDeviceID = this.route.snapshot.paramMap.get('idMediaDevice');
    this.initMediaDevice();
    this.loadMediaDevices();

    this.initiated = true;
  };

  public init(_id: string)
  {
    this.mediaDevice.deviceGroup = false;

    if (_id === 'new')
    {
      this.mediaDeviceID = null;
    }
    else
    {
      this.mediaDeviceID = _id;
    }

    this.loadRoles();
    this.loadMediaDevices();
    this.initMediaDevice();

    this.initiated = true;
  };

  public asDevice()
  {
    this.mode = 'device';
    this.mediaDevice.deviceGroup = false;
  };

  public asGroup()
  {
    this.mode = 'group';
    this.mediaDevice.deviceGroup = true;
  };

  private initMediaDevice()
  {
    if (this.mediaDeviceID === 'new')
    {
      this.mediaDeviceID = null;
    }

    if (this.mediaDeviceID === null)
    {
      this.mediaDevice = new MediaDevice();
      this.mediaDevice.deviceGroup = this.mode === 'group' ? true : false;
    }
    else
    {
      this.loadMediaDevice();
    }
  };

  private loadRoles()
  {
    this.rolesService.GetRolesForSelection().subscribe((_data: IRole[]) =>
    {
      this.roles = [];
      for (var i = 0; i < _data.length; i++)
      {
        var tmp = new MediaDeviceRoleSelectable();
        tmp.role = _data[i].roleID;
        tmp.roleName = _data[i].roleName;
        tmp.itemID = this.mediaDeviceID;
        this.roles.push(tmp);
      }

      this.loadSelectedRoles();
    });
  };

  private loadSelectedRoles()
  {
    this.mediaDevicesService.GetRolesOfMediaDevice(this.mediaDeviceID).subscribe((_roleIDs) =>
    {
      for (var i = 0; i < this.roles.length; i++)
      {
        this.roles[i].selected = _roleIDs.indexOf(this.roles[i].role) >= 0;
      }
    });
  };

  private loadMediaDevice()
  {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + this.mediaDeviceID))
      .subscribe(result =>
      {
        this.mediaDevice = result.data as IMediaDevice;
        this.getStartupDaysFromModel();
        this.getStartupTimeFromModel();
      }, error => console.error(error));
  };

  private loadMediaDevices()
  {
    if (this.mode === 'group')
    {
      var tmp = this.mediaDeviceID !== null
        ? this.mediaDeviceID
        : this.utilsService.EmptyGuid();

      this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + tmp + '/groups'))
        .subscribe(result =>
        {
          this.mediaDevices = result.data as IMediaDeviceSelectable[];
        }, error => console.error(error));
    }
  };

  private setDevicesInGroup()
  {
    var data = {
      MediaDeviceIDs: []
    };

    if (this.mode === 'device' && this.mediaDeviceGroupID !== null)
    {
      data.MediaDeviceIDs.push(this.mediaDeviceID);

      this.http
        .put<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + this.mediaDeviceGroupID + '/groups'), data)
        .subscribe(
          result =>
          {
            if (result.success)
            {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
              this.onClose.emit();
            }
            else
            {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    }
    else if (this.mode === 'group')
    {
      for (var i = 0; i < this.mediaDevices.length; i++)
      {
        if (this.mediaDevices[i].selected === true)
        {
          data.MediaDeviceIDs.push(this.mediaDevices[i].mediaDeviceID);
        }
      }

      this.http
        .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + this.mediaDeviceID + '/groups'), data)
        .subscribe(
          result =>
          {
            if (result.success)
            {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
              this.onClose.emit();
            }
            else
            {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    }
  };

  private save()
  {
    if (!this.mediaDevice.deviceName)
    {
      this.dialogGeneric = this.dialog.open(DialogComponent, {
        autoFocus: true,
        height: '250px',
        width: '550px'
      });
      this.dialogGeneric.componentInstance.options.actionOk = true;
      this.dialogGeneric.componentInstance.options.title = application.getRawText('common.error.msg');
      this.dialogGeneric.componentInstance.options.message = application.getRawText('mediadevice.single.save.error.missing-name.msg');
      return;
    }

    this.setStartupDaysToModel();
    this.setStartupTimeToModel();

    var roleIDs = [];
    for (var i = 0; i < this.roles.length; i++)
    {
      if (this.roles[i].selected)
      {
        roleIDs.push(this.roles[i].role);
      }
    }

    this.mediaDevicesService.Save(this.mediaDeviceID, this.mediaDevice, roleIDs).subscribe((_result: IReturnState) =>
    {
      if (_result.success)
      {
        this.mediaDevice = _result.data as IMediaDevice;
        this.mediaDeviceID = this.mediaDevice.mediaDeviceID;
        this.getStartupDaysFromModel();
        this.getStartupTimeFromModel();

        if (this.mode === 'group' || this.mediaDeviceGroupID !== null)
        {
          this.setDevicesInGroup();
        }
        else
        {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
          this.onClose.emit();
        }
      }
      else
      {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  private createActivationKey() {
    this.mediaDevicesService.CreateActivationKey(this.mediaDeviceID).subscribe(result => {

      this.snackBarRef = this.snackBar.open('Activation Key: ' + result, application.getRawText('common.close.msg'), { duration: 20000 });
    }, error => console.error(error));
  }

  private close()
  {
    this.onClose.emit();
  };

  private setStartupDaysToModel()
  {
    this.mediaDevice.startupDays = this.weekDays.ConvertStartupDaysToValue(this.days);
  };

  private getStartupDaysFromModel()
  {
    this.weekDays.ConvertStartupDaysFromValue(this.days, this.mediaDevice.startupDays);
  };

  private setStartupTimeToModel()
  {
    let now: string = this.dateTimeService.Now();
    let dtStart: Date = new Date(this.dateTimeService.SetTimeToDate(this.startTime, now));
    let dtEnd: Date = new Date(this.dateTimeService.SetTimeToDate(this.endTime, now));

    this.mediaDevice.startupTimeInSeconds = this.dateTimeService.GetUtcTotalMinutes(dtStart);
    this.mediaDevice.shutdownTimeInSeconds = this.dateTimeService.GetUtcTotalMinutes(dtEnd);
  }

  private getStartupTimeFromModel()
  {
    var hoursStart = Math.floor(this.mediaDevice.startupTimeInSeconds / 60);
    var minutesStart = this.mediaDevice.startupTimeInSeconds - (hoursStart * 60);

    var hoursEnd = Math.floor(this.mediaDevice.shutdownTimeInSeconds / 60);
    var minutesEnd = this.mediaDevice.shutdownTimeInSeconds - (hoursEnd * 60);

    let startDate = this.dateTimeService.FormatDateTimeAsISO(new Date(2000, 1, 1, hoursStart, minutesStart, 0, 0));
    this.startTime = this.dateTimeService.GetTime(startDate, true);

    let endDate = this.dateTimeService.FormatDateTimeAsISO(new Date(2000, 1, 1, hoursEnd, minutesEnd, 0, 0));
    this.endTime = this.dateTimeService.GetTime(endDate, true);

    //this.startTime = hoursStart + ':' + minutesStart;
    //this.endTime = hoursEnd + ":" + minutesEnd;
  };

  private onStartupTimeChangeHandler(_event)
  {

  };

}
