import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ICanBeBusy, IsBusy } from '../../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { GlobalSettingsService } from '../../../../../../app-pwp/src/app/core/services/data/globalsettings.service';
import { IReturnState } from '../../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IMediaDevice } from '../../interfaces/IMediaDevice';
import { evision5 } from '../../globals';
import { application } from '../../../../../../app-pwp/src/app/core/globals';


@Injectable({
  providedIn: 'root',
})

export class MediaDevicesService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private http: HttpWorker;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);

    this.globalSettingsService.GetSettings().subscribe(_state =>
    {
    });
  }

  /**
   * Gets the selected roles of a mediaDevice.
   * @param _mediaJobID
   * @param _scene
   */
  public GetRolesOfMediaDevice(_mediaDeviceID: string): EventEmitter<number[]>
  {
    let onDone = new EventEmitter<number[]>();

    if (!application.isAdmin)
    {
      onDone.emit([]);
      return onDone;
    }

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + _mediaDeviceID + '/roles'))
      .subscribe(result =>
      {
        var data = result.data as number[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  public CreateActivationKey(_mediaDeviceID: string): EventEmitter<string> {
    let onDone = new EventEmitter<string>();

    if (!application.isAdmin) {
      onDone.emit();
      return onDone;
    }

    this.http.get<string>(evision5.buildApi(this.baseUrl, 'mediadevice/' + _mediaDeviceID + '/activationkey'))
      .subscribe(result => {
        var data = result;
 
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  }

  /**
   * Sets the roles of a mediadevice.
   * @param _mediaDeviceID
   */
  public SetRolesOfMediaDevice(_mediaDeviceID: string, _roleIDs: number[]): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + _mediaDeviceID + '/roles'), _roleIDs)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Saves mediaDevice including role-assignment.
   * @param _mediaDeviceID
   * @param _mediaDevice
   * @param _roleIDs
   */
  public Save(_mediaDeviceID: string, _mediaDevice: IMediaDevice, _roleIDs: number[]): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = _mediaDeviceID === null
      ? evision5.buildApi(this.baseUrl, 'mediadevice')
      : evision5.buildApi(this.baseUrl, 'mediadevice/' + _mediaDeviceID);

    this.http.post<IReturnState>(url, _mediaDevice).subscribe((_resultSave: IReturnState) =>
    {
      if (_resultSave.success)
      {
        var mediaDeviceID = (_resultSave.data as any).id;
        this.SetRolesOfMediaDevice(mediaDeviceID, _roleIDs).subscribe((_resultRoles: IReturnState) =>
        {
          _resultRoles.data = _resultSave.data;
          onDone.emit(_resultRoles);
        });
      }
      else
      {
        onDone.emit(_resultSave);
      }
    },
      error => console.error(error));

    return onDone;
  };

  ///**
  // * Saves a mediaJob.
  // * @param _mediaJob
  // */
  //public SaveJob(_mediaJob: IMediaJob, _mediaJobID: string): EventEmitter<IReturnState>
  //{
  //  return this.save(_mediaJob, _mediaJobID, false);
  //};

  ///**
  // * Saves a scene.
  // * @param _mediaJob
  // */
  //public SaveScene(_mediaJob: IMediaJob, _mediaJobID: string): EventEmitter<IReturnState>
  //{
  //  return this.save(_mediaJob, _mediaJobID, true);
  //};

  //private save(_mediaJob: IMediaJob, _mediaJobID: string, _scene: boolean): EventEmitter<IReturnState>
  //{
  //  let onDone = new EventEmitter<IReturnState>();

  //  var action = _scene
  //    ? 'scene'
  //    : 'mediajob';

  //  var url = _mediaJobID !== null
  //    ? evision5.buildApi(this.baseUrl, action + '/' + _mediaJob.mediaJobID)
  //    : evision5.buildApi(this.baseUrl, action + '');

  //  this.http.post<IReturnState>(url, _mediaJob)
  //    .subscribe(result =>
  //    {
  //      var data = result as IReturnState;
  //      onDone.emit(data);
  //    }, error => console.error(error));

  //  return onDone;
  //};


  ///**
  // * Deletes a mediaJob.
  // * @param _mediaJob
  // */
  //public DeleteJob(_mediaJobID: string): EventEmitter<IReturnState>
  //{
  //  return this.delete(_mediaJobID, false);
  //};

  ///**
  // * Deletes a scene.
  // * @param _mediaJob
  // */
  //public DeleteScene(_mediaJobID: string): EventEmitter<IReturnState>
  //{
  //  return this.delete(_mediaJobID, true);
  //};

  //private delete(_mediaJobID: string, _scene: boolean): EventEmitter<IReturnState>
  //{
  //  let onDone = new EventEmitter<IReturnState>();

  //  var action = _scene
  //    ? 'scene'
  //    : 'mediajob';

  //  var url = evision5.buildApi(this.baseUrl, action + '/' + _mediaJobID);

  //  this.http.delete<IReturnState>(url, {})
  //    .subscribe(result =>
  //    {
  //      var data = result as IReturnState;
  //      onDone.emit(data);
  //    }, error => console.error(error));

  //  return onDone;
  //};

  ///**
  // * Gets all jobs of a scene.
  // * @param _sceneID
  // */
  //public GetJobsOfScene(_sceneID: string): EventEmitter<IMediaJob[]>
  //{
  //  var onDone = new EventEmitter<IMediaJob[]>();

  //  this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'scenes/' + _sceneID + '/mediajobs'))
  //    .subscribe(result =>
  //    {
  //      var tmp = result.data as IMediaJob[];

  //      if (tmp !== null)
  //      {
  //        tmp.sort((_a, _b) => _a.jobName.localeCompare(_b.jobName));
  //      }

  //      onDone.emit(tmp);
  //    }, error => console.error(error));

  //  return onDone;
  //};

  ///**
  // * Saves selected mediaJobs to a scene.
  // * @param _sceneID
  // * @param _jobs
  // */
  //public SaveJobsToScene(_sceneID: string, _jobs: IMediaJobSelectable[]): EventEmitter<IReturnState>
  //{
  //  var onDone = new EventEmitter<IReturnState>();

  //  var ids = [];
  //  for (var i = 0; i < _jobs.length; i++)
  //  {
  //    if (_jobs[i].selected)
  //    {
  //      ids.push(_jobs[i].mediaJobID);
  //    }
  //  }

  //  this.http.post<IReturnState>(evision5.buildApi(this.baseUrl, 'scenes/' + _sceneID + '/mediajobs'), ids)
  //    .subscribe(result =>
  //    {
  //      onDone.emit(result);
  //    }, error => console.error(error));

  //  return onDone;
  //};


  ///**
  // * Saves selected mediaJobs to a scene.
  // * @param _sceneID
  // * @param _jobs
  // */
  //public RemoveJobFromScene(_sceneID: string, _jobID: string): EventEmitter<IReturnState>
  //{
  //  var onDone = new EventEmitter<IReturnState>();

  //  this.http.delete<IReturnState>(evision5.buildApi(this.baseUrl, 'scenes/' + _sceneID + '/mediajob/' + _jobID), {})
  //    .subscribe(result =>
  //    {
  //      onDone.emit(result);
  //    }, error => console.error(error));

  //  return onDone;
  //};

  ///**
  // * Starts a job/scene.
  // * @param _mediaJobID
  // */
  //public Start(_mediaJobID: string): EventEmitter<IReturnState>
  //{
  //  var onDone = new EventEmitter<IReturnState>();

  //  this.http
  //    .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + _mediaJobID + '/start'), {})
  //    .subscribe(
  //      result =>
  //      {
  //        onDone.emit(result);
  //      },
  //      error => console.error(error));

  //  return onDone;
  //};

  ///**
  // * Stops a job/scene.
  // * @param _mediaJobID
  // */
  //public Stop(_mediaJobID: string): EventEmitter<IReturnState>
  //{
  //  var onDone = new EventEmitter<IReturnState>();

  //  this.http
  //    .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + _mediaJobID + '/stop'), {})
  //    .subscribe(
  //      result =>
  //      {
  //        onDone.emit(result);
  //      },
  //      error => console.error(error));

  //  return onDone;
  //};
}
