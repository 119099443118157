import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { VideoWrapperDirective } from './videowrapper.directive';
import { WebSiteWrapperDirective } from './websitewrapper.directive';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LayoutModule } from '@angular/cdk/layout';

import { AppRoutingModule } from './app-routing.module';
import { AppE5Component } from './app-e5.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './login/login.component';
import { UserSettingsComponent } from './usersettings/usersettings.component';
import { SettingsComponent } from './settings/settings.component';
import { SetPasswordComponent } from './usersettings/setpassword/setpassword.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MediaListsSummaryComponent } from './medialists-summary/medialists-summary.component';
import { MediaListSingleComponent } from './medialist-single/medialist-single.component';
import { MediaJobsSummaryComponent } from './mediajobs-summary/mediajobs-summary.component';
import { MediaJobSingleComponent } from './mediajob-single/mediajob-single.component';
import { MediaActivitiesDurationComponent } from './mediaactivities-duration/mediaactivities-duration.component';
import { MediaActivitiesSummaryComponent } from './mediaactivities-summary/mediaactivities-summary.component';
import { MediaActivitySingleComponent } from './mediaactivity-single/mediaactivity-single.component';
import { MediaDevicesSummaryComponent } from './mediadevices-summary/mediadevices-summary.component';
import { MediaDeviceSingleComponent } from './mediadevice-single/mediadevice-single.component';
import { MediaDevicesStartuptimeComponent } from './mediadevices-startuptime/mediadevices-startuptime.component';
import { MediaDeviceStartupExceptionSummaryComponent } from './mediadevicestartupexception-summary/mediadevicestartupexception-summary.component';
import { MediaDeviceStartupExceptionSingleComponent } from './mediadevicestartupexception-single/mediadevicestartupexception-single.component';
import { MediaListSetLinkedListsComponent } from './medialist-setlinklists/medialist-setlinklists.component';
import { ScenesSummaryComponent } from './scenes/scenes-summary/scenes-summary.component';
import { SceneSingleComponent } from './scenes/scene-single/scene-single.component';
import { SceneJobsSummaryComponent } from './scenes/scene-jobs-summary/scene-jobs-summary.component';
import { SceneJobsEditSummaryComponent } from './scenes/scene-jobs-edit-summary/scene-jobs-edit-summary.component';
import { FileExplorerComponent } from './fileexplorer/fileexplorer.component';
import { EditDirectoryComponent } from './fileexplorer/editdirectory/editdirectory.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DialogComponent } from '../../../app-pwp/src/app/dialog/dialog.component';
import { BusyIndicatorModule } from '../../../app-pwp/src/app/busy-indicator/busy-indicator.module';
import { LocalizeModule } from '../../../app-pwp/src/app/localize/localize.module';
import { RoleFunctionModule } from '../../../app-pwp/src/app/rolefunction/rolefunction.module';
import { FormsModule } from '@angular/forms';
import { ApiKeysSummaryComponent } from '../../../app-pwp/src/app/apikeys-summary/apikeys-summary.component';
import { ApiKeySingleComponent } from '../../../app-pwp/src/app/apikey-single/apikey-single.component';
import { ApiKeysSummaryModule } from '../../../app-pwp/src/app/apikeys-summary/apikeys-summary.module';
import { ApiKeySingleModule } from '../../../app-pwp/src/app/apikey-single/apikey-single.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconComponent, IconModule } from '../../../app-pwp/src/app/icon/icon.component';
import { SlackModule } from '../../../app-pwp/src/app/slack/slack.module';
import { MediaLayoutsSummaryComponent } from './medialayouts/medialayouts-summary/medialayouts-summary.component';
import { MediaLayoutSingleComponent } from './medialayouts/medialayout-single/medialayout-single.component';
import { NumericInputModule } from '../../../app-pwp/src/app/numeric-input/numeric-input.module';

@NgModule({
  declarations: [
    AppE5Component,

    VideoWrapperDirective,
    WebSiteWrapperDirective,

    NavMenuComponent,
    LoginComponent,
    UserSettingsComponent,
    SettingsComponent,
    SetPasswordComponent,

    LandingPageComponent,
    MediaListsSummaryComponent,
    MediaListSingleComponent,
    MediaJobsSummaryComponent,
    MediaJobSingleComponent,
    MediaActivitiesDurationComponent,
    MediaActivitiesSummaryComponent,
    MediaActivitySingleComponent,
    DialogComponent,
    MediaDevicesSummaryComponent,
    MediaDeviceSingleComponent,
    MediaDevicesStartuptimeComponent,
    MediaDeviceStartupExceptionSummaryComponent,
    MediaDeviceStartupExceptionSingleComponent,
    MediaListSetLinkedListsComponent,
    ScenesSummaryComponent,
    SceneSingleComponent,
    SceneJobsSummaryComponent,
    SceneJobsEditSummaryComponent,
    MediaLayoutsSummaryComponent,
    MediaLayoutSingleComponent,

    FileExplorerComponent,
    EditDirectoryComponent,

    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FontAwesomeModule,
    IconModule, 

    BusyIndicatorModule,
    LocalizeModule,
    NumericInputModule,
    RoleFunctionModule,
    ApiKeySingleModule,
    ApiKeysSummaryModule,
    SlackModule,

    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    MatSliderModule,
    MatExpansionModule,
    MatIconModule,
    NgxFileDropModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    MatBadgeModule,
    MatSortModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    LayoutModule
  ],
  exports: [
    AppE5Component
  ],
  providers: [],
  bootstrap: [AppE5Component]
})
export class AppModule { }

@NgModule({
  id: 'app-evision5'
})
export class AppEvision5
{
  static forRoot(): ModuleWithProviders<AppModule>
  {
    return {
      ngModule: AppModule,
      providers: []
    }
  }
}
