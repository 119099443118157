import { Component, Inject, EventEmitter, Output, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { IState } from '../../../../app-pwp/src/app/core/interfaces/IState';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { IGlobal } from '../../../../app-pwp/src/app/core/interfaces/IGlobal';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent
{
  private application: IGlobal = application;

  @Output() stateChanged = new EventEmitter<IState>();
  @Input() isSessionLost: boolean = false;

  private version: string = '0.0.0.0';
  private user: string = '';
  private isAdmin: boolean = false;

  private isExpanded = false;

  http: HttpClient;
  baseUrl: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string)
  {
    this.http = http;
    this.baseUrl = baseUrl;

    this.version = application.version;
    this.user = application.user;
    this.isAdmin = application.isAdmin;
  };

  private collapse()
  {
    this.isExpanded = false;
  }

  private toggle()
  {
    this.isExpanded = !this.isExpanded;
  }

  private doLogout()
  {
    this.http
      .get<IState>(this.baseUrl + 'api/pwp/security/logout')
      .subscribe(result =>
      {
        this.stateChanged.emit(result);
        application.technicalRoleNames = null;
      }, error => console.error(error));
  }
}
