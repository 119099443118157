import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SetPasswordComponent } from './setpassword/setpassword.component';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { IUserSettings } from '../core/interfaces/IUserSettings';
import UserSettings from '../core/models/UserSettings';
import SystemLanguage from '../../../../app-pwp/src/app/core/models/SystemLanguage';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { WeekDaysService } from '../../../../app-pwp/src/app/core/services/common/weekdays.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { SystemService } from '../../../../app-pwp/src/app/core/services/data/system.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';

@Component({
  selector: 'app-usersettings',
  templateUrl: './usersettings.component.html',
  styleUrls: ['./usersettings.component.css']
})

export class UserSettingsComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  private userSettings: IUserSettings = new UserSettings();
  private userSettingsLoaded: IUserSettings = new UserSettings();
  private languages: SystemLanguage[] = [];

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public onClose = new EventEmitter();

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;

  private textUserLanguage: string = application.getRawText('usersettings.single.form.userlanguage.title');

  private dialogGeneric: MatDialogRef<DialogComponent> = null;
  private dialogSetPassword: MatDialogRef<SetPasswordComponent> = null;

  private keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private weekDays: WeekDaysService,
    private dateTime: DateTimeService,
    private route: ActivatedRoute,
    private uiKeyHandleService: UiKeyHandleService,
    private dateTimeService: DateTimeService,
    private systemService: SystemService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
    this.keyHandler.OnCancel.subscribe(() => this.close());
  };

  private ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }
    this.userSettings = new UserSettings();
    this.userSettings.userLanguage = "default";

    this.initUserSettings();

    this.initiated = true;
  };

  private init(_id: string)
  {

  };

  private initLanguages()
  {

  }

  private initUserSettings()
  {
    this.loadUserSettings();
  };

  private loadUserSettings()
  {
    this.systemService.GetLanguagesForSelection()
      .subscribe((_result) =>
      {
        this.languages = _result as SystemLanguage[];
      });

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'usersettings'))
      .subscribe(result =>
      {
        this.userSettings = result.data as IUserSettings;
        this.userSettingsLoaded.userLanguage = this.userSettings.userLanguage;
      }, error => console.error(error));
  };

  private save()
  {
    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'usersettings'), this.userSettings)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            if (this.userSettingsLoaded.userLanguage !== this.userSettings.userLanguage)
            {
              this.askForReload();
            }

            this.loadUserSettings();

            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
            this.onClose.emit();
          }
          else
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  private askForReload()
  {
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('usersettings.languagechanged.pleasereload.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
    {
      if (_action.action !== 'yes')
      {
        return;
      }

      window.location.reload();
    });
  };

  private close()
  {
    this.onClose.emit();
  };

  private openChangePasswordDialog()
  {
    this.dialogSetPassword = this.dialog.open(SetPasswordComponent, {
      autoFocus: true,
      height: '450px',
      width: '550px'
    });
    this.dialogSetPassword.componentInstance.onClose.subscribe((_result) =>
    {
      this.dialogSetPassword.close();
      this.dialogSetPassword = null;
    });
  };
}
