import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { IResetPassword } from '../../core/interfaces/IResetPassword';
import ResetPassword from '../../core/models/ResetPassword';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})

export class SetPasswordComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;
  private item: IResetPassword = new ResetPassword();

  private textCurrent: string = application.getRawText('usersettings.setpassword.form.current.title');
  private textNew: string = application.getRawText('usersettings.setpassword.form.new.title');
  private textRetyped: string = application.getRawText('usersettings.setpassword.form.retyped.title');

  private keyHandler: KeyHandler;

  //dialogGeneric: MatDialogRef<DialogComponent>;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    //private security: SecurityService,
    private route: ActivatedRoute,
    private uiKeyHandleService: UiKeyHandleService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
    this.keyHandler.OnCancel.subscribe(() => this.triggerOnClose());
  };

  ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }

    this.initiated = true;
  };

  private init(_mediaJobID: string)
  {
    this.initiated = true;
  };

  private save()
  {
    this.http.post<IReturnState>(application.buildApi(this.baseUrl, 'security/changepassword'), this.item)
      .subscribe((_result) =>
      {
        if (_result.success)
        {
          this.triggerOnClose();
        }
        else if (_result.stateID === 96)
        {
          this.showError('usersettings.setpassword.error.complexityfailed.msg');
        }
        else if (_result.stateID === 98)
        {
          this.showError('usersettings.setpassword.error.retypediswrong.msg');
        }
        else if (_result.stateID === 97)
        {
          this.showError('usersettings.setpassword.error.currentiswrong.msg');
        }
        else
        {
          this.showError('usersettings.setpassword.failed.msg');
        }
      });

  };

  private showError(_msg: string)
  {
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionOk = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText(_msg);
  }

  private triggerOnClose()
  {
    this.onClose.emit();
  };
};

