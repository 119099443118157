import { Injectable } from '@angular/core';
import { application } from 'globals';
import { Router } from '@angular/router';
import { IGlobal } from '../../interfaces/IGlobal';

@Injectable({
  providedIn: 'root',
})

export class DateTimeService
{
  private application: IGlobal = application;

  constructor(private router: Router) { }

  public TimeToLunchOrDefault(_dateTimeString: string): string
  {
    let tmp = this.TimeToLunch(_dateTimeString);
    if (tmp === null)
    {
      return this.Now();
    }
    else
    {
      return tmp;
    }
  };

  public TimeToLunch(_dateTimeString: string): string
  {
    if (_dateTimeString === null)
    {
      return null;
    }

    var tmp = new Date(_dateTimeString);
    tmp.setUTCHours(12);
    tmp.setUTCMinutes(0);
    tmp.setUTCSeconds(0);
    tmp.setUTCMilliseconds(0);

    var asString = this.FormatDateTimeAsISO(tmp);
    return asString;
  };

  public MaxDate(): string
  {
    let max = '9999-12-31T23:59:59.997Z';
    return max;
  };

  public Now(): string
  {
    let now = new Date();
    let nowString = this.FormatDateTimeAsISO(now);
    return nowString;
  };

  /**
   * Formats a time-string of the passed date-object.
   * @param _dateTime
   * @param _short Indicates whether the string should only contain hours and minutes, but no seconds.
   */
  public FormatTime(_dateTime: Date, _short: boolean = false): string
  {
    var tmp = _dateTime; tmp.getUTCHours()
    var time = tmp.getUTCHours().toString().padStart(2, "0")
      + ':' + tmp.getUTCMinutes().toString().padStart(2, "0")
      + (_short ? '' : (':' + tmp.getUTCSeconds().toString().padStart(2, "0")));
    return time;
  };

  public EnsureUTC(_dateTimeString: string): string
  {
    if (_dateTimeString === null)
    {
      return null;
    }

    if (_dateTimeString.endsWith("Z") === false)
    {
      _dateTimeString = _dateTimeString + 'Z';
    }

    return _dateTimeString;
  }

  public FormatDateTimeAsISO(_dateTime: Date): string
  {
    /*
     * Returns the dateTime as ISO string but it takes the date-time-information
     * directly and does not take notice of the timzone-offset.
     **/
    var tmp = _dateTime;

    var isoString =
      tmp.getFullYear().toString() + '-' +
      (tmp.getMonth() + 1).toString().padStart(2, "0") + '-' +
      tmp.getDate().toString().padStart(2, "0") + 'T' +
      tmp.getHours().toString().padStart(2, "0") + ':' +
      tmp.getMinutes().toString().padStart(2, "0") + ':' +
      tmp.getSeconds().toString().padStart(2, "0") + '.' +
      tmp.getMilliseconds().toString() +
      'Z';

    return isoString;
  };

  /**
   * Returns the time from the dateTimeString or - if null - a default time.
   * @param _dateTimeString
   */
  public GetTimeOrDefault(_dateTimeString: string, _short: boolean = false): string
  {
    let tmp = this.GetTime(_dateTimeString, _short);
    if (tmp === null)
    {
      return '00:00';
    }
    else
    {
      return tmp;
    }
  };

  public GetTime(_dateTimeString: string, _short: boolean = false): string
  {
    if (_dateTimeString === null)
    {
      return null;
    }

    _dateTimeString = this.EnsureUTC(_dateTimeString);

    var tmp = new Date(_dateTimeString);
    var time = this.FormatTime(tmp, _short);
    return time;
  };

  /**
   * Sets a time of an existing date.
   * If time is NULL, the date is returned.
   * If date is NULL, also NULL is returned.
   * @param _timeString
   * @param _baseDate
   */
  public SetTimeToDate(_timeString: string, _baseDate: string): string
  {
    if (_baseDate != null)
    {
      var tmp = new Date(_baseDate);

      if (_timeString != null)
      {
        var splittedStart = _timeString.split(':');
        tmp.setHours(parseInt(splittedStart[0]));
        tmp.setMinutes(parseInt(splittedStart[1]));
        tmp.setSeconds(0);
        tmp.setMilliseconds(0);
      }

      var dateTimeString = this.FormatDateTimeAsISO(tmp);
      return dateTimeString;
    }

    return null;
  };

  public GetUtcTotalMinutes(_dateTime: Date): number
  {
    var seconds = _dateTime.getUTCHours() * 60 + _dateTime.getUTCMinutes();
    return seconds;
  };

}
