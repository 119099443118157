import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ISetDurationOfAll } from '../core/interfaces/ISetDurationOfAll';
import SetDurationOfAll from '../core/models/SetDurationOfAll';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { evision5 } from '../core/globals';
import { application } from '../../../../app-pwp/src/app/core/globals';

@Component({
  selector: 'app-mediaactivities-duration',
  templateUrl: './mediaactivities-duration.component.html',
  styleUrls: ['./mediaactivities-duration.component.css']
})

export class MediaActivitiesDurationComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  public onSaved = new EventEmitter();

  public item: ISetDurationOfAll = new SetDurationOfAll();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  private textPlaceholderDuration: string = application.getRawText('mediaactivities.duration.form.duration.placeholder');

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    //this.security.checkForRolesByName('devices.summary');
  };

  private ngOnInit()
  {
  };

  private save()
  {
    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.item.mediaListID + '/duration'), this.item)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.onSaved.emit();
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
          }
          else
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };
}
