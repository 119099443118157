import { Injectable, EventEmitter } from '@angular/core';
import { application } from 'globals';
import { Router } from '@angular/router';
import { IGlobal } from '../../interfaces/IGlobal';

@Injectable({
  providedIn: 'root',
})

export class UiKeyHandleService
{
  private application: IGlobal = application;

  constructor(private router: Router) { }

  public GetKeyHandler(): KeyHandler
  {
    return new KeyHandler();
  };
}

export class KeyHandler
{
  public OnSave = new EventEmitter();
  public OnCancel = new EventEmitter();

  public TriggerOnKeyDown(_event)
  {
    this.onKeyDown(_event);
  };

  private onKeyDown(_event): void
  {
    // Detect platform
    if (navigator.platform.match('Mac'))
    {
      this.handleMacKeyEvents(_event);
    }
    else
    {
      this.handleWindowsKeyEvents(_event);
    }
  };

  private handleMacKeyEvents(_event)
  {
    // MetaKey documentation
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/metaKey
    let charCode = String.fromCharCode(_event.which).toLowerCase();
    if (_event.metaKey && charCode === 's')
    {
      // Action on Cmd + S
      _event.preventDefault();
      _event.stopImmediatePropagation();
      this.OnSave.emit();
    }
    else if (_event.keyCode === 13 || _event.keyCode === 27)
    {
      _event.preventDefault();
      _event.stopImmediatePropagation();
      this.OnCancel.emit();
    }
  };

  private handleWindowsKeyEvents(_event)
  {
    let charCode = String.fromCharCode(_event.which).toLowerCase();
    if (_event.ctrlKey && charCode === 's')
    {
      // Action on Ctrl + S
      _event.preventDefault();
      _event.stopImmediatePropagation();
      this.OnSave.emit();
    }
    else if (_event.keyCode === 13 || _event.keyCode === 27)
    {
      _event.preventDefault();
      _event.stopImmediatePropagation();
      this.OnCancel.emit();
    }
  };
};
