import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { IMediaList } from '../core/interfaces/IMediaList';
import { IMediaListSelectable } from '../core/interfaces/IMediaListSelectable';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IMediaListExtended } from '../core/interfaces/IMediaListExtended';
import { IMediaListLink } from '../core/interfaces/IMediaListLink';

@Component({
  selector: 'app-medialist-setlinklists',
  templateUrl: './medialist-setlinklists.component.html',
  styleUrls: ['./medialist-setlinklists.component.css']
})

export class MediaListSetLinkedListsComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public onClose = new EventEmitter();

  private mediaListID: string = null;
  private mediaList: IMediaList = null;
  private mediaLists: IMediaListSelectable[] = null;

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;
  private textListName: string = application.getRawText('medialistsetlinkedlists.summary.form.listname.title');
  private textTotalDuration: string = application.getRawText('medialistsetlinkedlists.summary.form.duration.title');
  private textMediatypes: string = application.getRawText('medialistsetlinkedlists.summary.form.mediaTypes.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
  };

  private ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }

    this.loadMediaList();
    this.loadMediaLists();

    this.initiated = true;
  };

  public init(_id: string)
  {
    this.mediaListID = _id;

    this.loadMediaList();
    this.loadMediaLists();

    this.initiated = true;
  };

  private loadMediaList()
  {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID))
      .subscribe(result =>
      {
        this.mediaList = result.data as IMediaListExtended;
      }, error => console.error(error));
  };

  private loadMediaLists()
  {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialists/nolinkedlists'))
      .subscribe(result =>
      {
        this.mediaLists = result.data as IMediaListSelectable[];
        this.loadLinkedMediaLists();
      }, error => console.error(error));
  };

  private loadLinkedMediaLists()
  {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID + '/linkedlists'))
      .subscribe(result =>
      {
        // Put all ids into a list for faster check.
        var tmp = result.data as IMediaListLink[],
          listLinkIDs = [];
        for (var i = 0; i < tmp.length; i++)
        {
          listLinkIDs.push(tmp[i].mediaListID);
        }

        // Check all lists if they have already been added.
        for (var f = 0; f < this.mediaLists.length; f++)
        {
          if (listLinkIDs.indexOf(this.mediaLists[f].mediaListID) >= 0)
          {
            this.mediaLists[f].selected = true;
          }
        }

      }, error => console.error(error));
  };

  private setLinkedLists()
  {
    var data = {
      MediaListIDs: []
    };

    for (var i = 0; i < this.mediaLists.length; i++)
    {
      if (this.mediaLists[i].selected === true)
      {
        data.MediaListIDs.push(this.mediaLists[i].mediaListID);
      }
    }

    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID + '/linkedlists'), data)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
            this.onClose.emit();
          }
          else
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  private save()
  {
    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID), this.mediaList)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.setLinkedLists();
            //this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
            //this.onClose.emit();
          }
          else
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  private close()
  {
    this.onClose.emit();
  };

}
