import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { IMediaListExtended } from '../core/interfaces/IMediaListExtended';
import MediaListExtended from '../core/models/MediaListExtended';
import { IMediaListRoleSelectable } from '../core/interfaces/IMediaListRoleSelectable';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { RolesService } from '../../../../app-pwp/src/app/core/services/data/roles.service';
import { MediaListsService } from '../core/services/data/medialists.service';
import { MediaListRoleSelectable } from '../core/models/MediaListRoleSelectable';
import { IRole } from '../../../../app-pwp/src/app/core/interfaces/IRole';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IGlobal } from '../../../../app-pwp/src/app/core/interfaces/IGlobal';

@Component({
  selector: 'app-medialist-single',
  templateUrl: './medialist-single.component.html',
  styleUrls: ['./medialist-single.component.css']
})

export class MediaListSingleComponent
{
  private application: IGlobal = application;

  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public mediaListID: string = null;
  private mediaList: IMediaListExtended = new MediaListExtended();

  private roles: IMediaListRoleSelectable[] = [];
  private selectedRoleIDs: number[] = [];

  private initiated: boolean = false;

  private textName: string = application.getRawText('medialist.single.form.name.title');
  private textTotalDuration: string = application.getRawText('medialist.single.form.duration.title');
  private textMediatypes: string = application.getRawText('medialist.single.form.mediaTypes.title');

  private textElementCount: string = application.getRawText('medialist.single.form.elementCount.title');
  private textCreateDate: string = application.getRawText('medialist.single.form.createDate.title');
  //dialogGeneric: MatDialogRef<DialogComponent>;

  private keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private rolesService: RolesService,
    private mediaListsService: MediaListsService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
  };

  private ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }

    this.mediaListID = this.route.snapshot.paramMap.get('id');
    this.initMediaList();
  };

  public initMediaList()
  {
    if (this.initiated)
    {
      return;
    }

    this.loadRoles();

    if (this.mediaListID === null || this.mediaListID == 'new')
    {
      this.mediaListID = null;
      this.mediaList = new MediaListExtended();
    }
    else
    {
      this.loadMediaList();
    }

    this.initiated = true;
  };

  private loadRoles()
  {
    this.rolesService.GetRolesForSelection().subscribe((_data: IRole[]) =>
    {
      this.roles = [];
      for (var i = 0; i < _data.length; i++)
      {
        var tmp = new MediaListRoleSelectable();
        tmp.role = _data[i].roleID;
        tmp.roleName = _data[i].roleName;
        tmp.itemID = this.mediaListID;
        this.roles.push(tmp);
      }

      this.loadSelectedRoles();
    });
  };

  private loadSelectedRoles()
  {
    this.mediaListsService.GetRolesOfMediaList(this.mediaListID).subscribe((_roleIDs) =>
    {
      for (var i = 0; i < this.roles.length; i++)
      {
        this.roles[i].selected = _roleIDs.indexOf(this.roles[i].role) >= 0;
      }
    });
  };

  private loadMediaList()
  {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID))
      .subscribe(result =>
      {
        this.mediaList = result.data as IMediaListExtended;
      }, error => console.error(error));
  };

  private save()
  {
    var roleIDs = [];
    for (var i = 0; i < this.roles.length; i++)
    {
      if (this.roles[i].selected)
      {
        roleIDs.push(this.roles[i].role);
      }
    }

    this.mediaListsService.Save(this.mediaList, this.mediaListID, roleIDs).subscribe(_result =>
    {
      if (_result.success)
      {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
        this.close();
      }
      else
      {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  private close()
  {
    this.onClose.emit(this.mediaList.mediaListID);
  };

}
