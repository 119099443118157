import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { application } from './../core/globals';
import { SecurityService } from './../core/services/common/security.service';
import { BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { IGlobal } from './../core/interfaces/IGlobal';

@Directive({
  selector: 'busyIndicator',
  inputs: ['isBusy']
})
export class BusyIndicatorDirective
{
  private sourceUrl: string = null;

  private delay: number = 500;

  private elImgIndicator: HTMLImageElement = null;
  private elFrame: HTMLDivElement = null;

  constructor(private el: ElementRef,
    private security: SecurityService)
  {
    this.el.nativeElement.className = 'busy-indicator';

    this.elFrame = document.createElement('div');
    this.elFrame.className = 'busy-indicator-frame';
    this.elFrame.title = application.getRawText('common.pleasewait.msg');
    this.el.nativeElement.append(this.elFrame);

    this.elImgIndicator = document.createElement('img');
    this.elImgIndicator.className = 'busy-indicator-img';
    this.elImgIndicator.src = '/assets/images/loading.svg';
    this.elFrame.appendChild(this.elImgIndicator);

  };

  private check()
  {
    if (this._isBusy === true)
    {
      setTimeout(() =>
      {
        if (this._isBusy === true)
        {
          this.elFrame.className = 'busy-indicator-frame visible';
        }
      }, this.delay);
    }
    else
    {
      this.elFrame.className = 'busy-indicator-frame';
    }
  };

  // --------------------------------------------------------------------------

  private _isBusy: boolean;

  get isBusy(): boolean { return this._isBusy; }

  @Input('isBusy')
  set isBusy(_value: boolean) { this._isBusy = _value; this.check(); }

  // --------------------------------------------------------------------------

  private canBeBusyWorker: BusyIndicatorWorker;

  get CanBeBusyWorker(): BusyIndicatorWorker { return this.canBeBusyWorker; }

  @Input('canBeBusyWorker')
  set CanBeBusyWorker(_value: BusyIndicatorWorker)
  {
    this.canBeBusyWorker = _value;
    if (this.canBeBusyWorker != null)
    {
      this.canBeBusyWorker.IsBusy.subscribe((_workerIsBusy: boolean) =>
      {
        this.isBusy = _workerIsBusy;
      });
    }
  }

  // --------------------------------------------------------------------------

  //@Output('onDurationChanged')
  //onDurationChanged: EventEmitter<number> = new EventEmitter<number>();

}

@NgModule({
  declarations: [
    BusyIndicatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BusyIndicatorDirective
  ]
})
export class BusyIndicatorModule { }
