import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { SlackService } from '../../core/services/data/slack.service';
import { IApiAccessSlack } from '../../core/interfaces/IApiAccessSlack';
import { HttpWorker, HttpWorkerFactoryService } from '../../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from '../../core/services/busyindicator/busyindicatorfactory.service';
import { IGlobal } from '../../core/interfaces/IGlobal';
import { application } from '../../core/globals';
import { DialogComponent } from '../../dialog/dialog.component';
import { SecurityService } from '../../core/services/common/security.service';
import { IReturnState } from '../../core/interfaces/IReturnState';
import { SlackSingleComponent } from '../slack-single/slack-single.component';

@Component({
  selector: 'app-slack-summary',
  templateUrl: './slack-summary.component.html',
  styleUrls: ['./slack-summary.component.css']
})

export class SlackSummaryComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  private application: IGlobal = application;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditSlack: MatDialogRef<SlackSingleComponent>;

  private displayColumnsAll: string[] = ['id', 'name', 'appID', 'responseDate', 'success', 'edit'];
  private displayedColumns: string[] = this.displayColumnsAll;

  private textUserFilter: string = application.getRawText('pwp.apikeys.summary.filter.user.new.title');

  public slacks: IApiAccessSlack[] = [];
  private slacksDataSource = new MatTableDataSource(this.slacks);

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private slackService: SlackService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.slackService);
  };

  private ngOnInit()
  {
    if (!this.security.CheckForRoles(true, []))
    {
      return;
    }

    this.loadSlacks();
  };

  private loadSlacks()
  {
    this.slackService.GetAll().subscribe((_slacks) =>
    {
      this.slacks = _slacks;
      this.slacksDataSource = new MatTableDataSource(this.slacks);
    });
  };

  private delete(_slack: IApiAccessSlack)
  {
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('pwp.slack.summary.delete.msg.title');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
    {
      if (_action.action !== 'yes')
      {
        return;
      }

      this.slackService.DeleteApiKey(_slack.id).subscribe(_returnState =>
      {
        var result = _returnState as IReturnState;
        if (result.success)
        {
          this.loadSlacks();
          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.slack.summary.deleted.title'), application.getRawText('common.close.msg'));
        }
        else
        {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });

    });
  };

  private edit(_slack: IApiAccessSlack)
  {
    this.dialogEditSlack = this.dialog.open(SlackSingleComponent, {
      autoFocus: true,
      height: '450px',
      width: '550px'
    });
    this.dialogEditSlack.componentInstance.slackId = _slack.id;
    this.dialogEditSlack.componentInstance.initSlack();
    this.dialogEditSlack.componentInstance.onClose.subscribe(() =>
    {
      this.dialogEditSlack.close();
      this.loadSlacks();
    });
  };

  private refresh()
  {
    this.loadSlacks();
  };
}
