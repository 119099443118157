import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MediaJobSingleComponent } from '../mediajob-single/mediajob-single.component';
import { MediaDeviceSingleComponent } from '../mediadevice-single/mediadevice-single.component';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaDevice } from '../core/interfaces/IMediaDevice';
import { IMediaJobWithGrouping } from '../core/interfaces/IMediaJobWithGrouping';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { UtilsService } from '../../../../app-pwp/src/app/core/services/common/utils.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import MediaJobWithGrouping from '../core/models/MediaJobWithGrouping';
import { IGlobal } from '../../../../app-pwp/src/app/core/interfaces/IGlobal';

@Component({
  selector: 'app-mediajobs-summary',
  templateUrl: './mediajobs-summary.component.html',
  styleUrls: ['./mediajobs-summary.component.css']
})
export class MediaJobsSummaryComponent
{
  private application: IGlobal = application;

  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditMediaJob: MatDialogRef<MediaJobSingleComponent>;
  private dialogNewMediaDevice: MatDialogRef<MediaDeviceSingleComponent>;

  private emptyGuid: string = null;

  private displayedColumnsAll: string[] = ['jobName', 'status', 'startStopAction', 'start', 'duration', 'mediaListName', 'edit'];
  private displayedColumnsDashboard: string[] = ['jobName', 'status', 'start', 'duration', 'deviceDisplay', 'mediaListName'];
  private displayedColumns: string[] = this.displayedColumnsAll;

  // runningMode: normal, dashboard
  private MODE_DASHBOARD: string = 'dashboard';
  private MODE_NORMAL: string = 'normal';
  @Input() public runningMode: string = this.MODE_NORMAL;

  private mediaJobID: string = null;
  private devicesDic: object = {};
  private devices: IMediaDevice[] = null;
  private groups: IMediaDevice[] = null;

  // Array with jobs received from server.
  private mediaJobs: IMediaJobWithGrouping[];

  // Array with jobs contain also the groups.
  private mediaJobsGrouped: IMediaJobWithGrouping[] = [];

  // Contains the collapsed groups.
  private mediaJobsReduced = [];

  private mediaDevices: IMediaDevice[];
  private groupingColumn: string = 'mediaDevice';

  private archived: boolean = false;
  private deviceID: string = null;

  private textDeviceFilter: string = application.getRawText('mediajobs.summary.top.devices.title');
  private textDeviceDevice: string = application.getRawText('mediajobs.summary.top.devices.device.title');
  private textDeviceGroup: string = application.getRawText('mediajobs.summary.top.devices.group.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private utilsService: UtilsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.emptyGuid = this.utilsService.EmptyGuid();
  };

  private ngOnInit()
  {
    this.displayedColumns = this.runningMode === this.MODE_DASHBOARD
      ? this.displayedColumnsDashboard
      : this.displayedColumnsAll;

    this.loadMediaJobs();
    this.loadMediaDevices();
  };

  private loadMediaJobs()
  {
    var url = this.archived
      ? evision5.buildApi(this.baseUrl, 'mediajobs/archived')
      : this.runningMode === this.MODE_NORMAL
        ? evision5.buildApi(this.baseUrl, 'mediajobs')
        : evision5.buildApi(this.baseUrl, 'mediajobs/running');

    // Devciefilter?
    if (this.deviceID !== null)
    {
      url = url + '/mediadevice/' + this.deviceID;
    }

    this.http.get<IReturnState>(url)
      .subscribe(result =>
      {
        this.mediaJobs = result.data as IMediaJobWithGrouping[];
        this.determineGroups();
      }, error => console.error(error));
  };

  private determineGroups()
  {
    this.mediaJobsGrouped = [];

    if (this.runningMode === this.MODE_DASHBOARD)
    {
      this.mediaJobsGrouped = this.mediaJobs;
      return;
    }

    // Temporary array to check whether device has been already added as group.
    var tmp = [];
    this.mediaJobs.forEach((_job) =>
    {
      _job.startTime = this.dateTimeService.EnsureUTC(_job.startTime);

      if (tmp.indexOf(_job.mediaDevice) < 0)
      {
        tmp.push(_job.mediaDevice);

        var group = new MediaJobWithGrouping();
        group.mediaDevice = _job.mediaDevice;
        group.deviceName = _job.deviceName === null
          ? application.getRawText('mediajobs.summary.grid.groups.withoutgroup')
          : _job.deviceName;

        group.isGroup = true;

        this.mediaJobsGrouped.push(group);
      }
      else
      {
        _job.isGroup = false;
      }

      _job.isGroup = false;

    });

    this.buildDataSource();
  };

  private loadMediaDevices()
  {
    this.devicesDic = {};

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevices/includinggroups'))
      .subscribe(result =>
      {
        var devices = result.data as IMediaDevice[];

        var tmp = [];
        devices.forEach((_device) => tmp.push(_device));

        this.mediaDevices = tmp;

        this.devices = [];
        this.groups = [];
        for (var i = 0; i < this.mediaDevices.length; i++)
        {
          this.devicesDic[this.mediaDevices[i].mediaDeviceID] = this.mediaDevices[i];

          if (this.mediaDevices[i].deviceGroup)
          {
            this.groups.push(this.mediaDevices[i]);
          }
          else
          {
            this.devices.push(this.mediaDevices[i]);
          }
        }

      }, error => console.error(error));
  };

  private refreshMedia(_mediaJobID: string)
  {
    this.http.post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + _mediaJobID + '/reloadmedia'), null)
      .subscribe(result =>
      {
      }, error => console.error(error));
  };

  private newMediaJob()
  {
    this.dialogEditMediaJob = this.dialog.open(MediaJobSingleComponent, {
      autoFocus: true,
      height: '750px',
      width: '550px'
    });
    this.dialogEditMediaJob.componentInstance.init('new');
    this.dialogEditMediaJob.componentInstance.onClose.subscribe(() =>
    {
      this.dialogEditMediaJob.close();
      this.loadMediaJobs();
    });
  };

  private editMediaJob(_mediaJobID: string)
  {
    this.mediaJobID = _mediaJobID;
    this.dialogEditMediaJob = this.dialog.open(MediaJobSingleComponent, {
      autoFocus: true,
      height: '750px',
      width: '550px'
    });
    this.dialogEditMediaJob.componentInstance.init(_mediaJobID);
    this.dialogEditMediaJob.componentInstance.onClose.subscribe(() =>
    {
      this.dialogEditMediaJob.close();
      this.loadMediaJobs();
    });
  };

  private start(_mediaJobID: string)
  {
    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + _mediaJobID + '/start'), {})
      .subscribe(
        result =>
        {
          this.loadMediaJobs();
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
          }
          else
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  private stop(_mediaJobID: string)
  {
    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + _mediaJobID + '/stop'), {})
      .subscribe(
        result =>
        {
          this.loadMediaJobs();
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
          }
          else
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  private delete(_mediaJobID: string)
  {
    this.mediaJobID = _mediaJobID;
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('mediajobs.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
    {
      if (_action.action !== 'yes')
      {
        return;
      }

      this.http
        .delete<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + _mediaJobID), {})
        .subscribe(
          _result =>
          {
            var result = _result as IReturnState;
            if (result.success)
            {
              this.loadMediaJobs();
              this.snackBarRef = this.snackBar.open(application.getRawText('mediajobs.summary.deleted.msg'), application.getRawText('common.close.msg'));
            }
            else
            {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    });
  };

  private editDevice(_event: any, _deviceID: string)
  {
    _event.preventDefault();
    _event.stopPropagation();
    _event.stopImmediatePropagation();

    var isGroup = false;
    for (var i = 0; i < this.mediaDevices.length; i++)
    {
      if (this.mediaDevices[i].mediaDeviceID === _deviceID)
      {
        isGroup = this.mediaDevices[i].deviceGroup;
        break;
      }
    }

    if (isGroup === true)
    {
      this.router.navigate(['mediadevices', 'group', _deviceID]);
    }
    else
    {
      this.dialogNewMediaDevice = this.dialog.open(MediaDeviceSingleComponent, {
        autoFocus: true,
        height: '750px',
        width: '550px'
      });
      this.dialogNewMediaDevice.componentInstance.init(_deviceID);
      this.dialogNewMediaDevice.componentInstance.asDevice();
      this.dialogNewMediaDevice.componentInstance.onClose.subscribe(() =>
      {
        this.dialogNewMediaDevice.close();
        this.loadMediaJobs();
      });
    }
  }

  private onDeviceFilterChanged()
  {
    this.loadMediaJobs();
  };

  /**
  * Rebuilds the datasource after any change to the criterions
  */
  private buildDataSource()
  {
    this.mediaJobsGrouped = this.groupBy(this.groupingColumn, this.mediaJobs, this.mediaJobsReduced);
  }

  /**
   * Groups the @param data by distinct values of a @param column
   * This adds group lines to the dataSource
   * @param _reducedGroups is used localy to keep track of the colapsed groups
   */
  private groupBy(_column: string, _data: any[], _reducedGroups?: any[])
  {
    if (!_column) return _data;
    let collapsedGroups = _reducedGroups;
    if (!_reducedGroups) collapsedGroups = [];
    const customReducer = (accumulator, currentValue) =>
    {
      let currentGroup = currentValue[_column];
      if (!accumulator[currentGroup])
      {
        var deviceName = currentValue.deviceName === null
          ? application.getRawText('mediajobs.summary.grid.groups.withoutgroup')
          : currentValue.deviceName;

        accumulator[currentGroup] = [{
          groupName: `${deviceName}`,
          value: currentValue[_column],
          isGroup: true,
          reduced: collapsedGroups.some((group) => group.value == currentValue[_column])
        }];
      }
      accumulator[currentGroup].push(currentValue);

      return accumulator;
    }
    let groups = _data.reduce(customReducer, {});
    let groupArray = Object.keys(groups).map(key => groups[key]);
    let flatList = groupArray.reduce((a, c) => { return a.concat(c); }, []);

    return flatList.filter((rawLine) =>
    {
      return rawLine.isGroup ||
        collapsedGroups.every((group) => rawLine[_column] != group.value);
    });
  }

  /**
 * Since groups are on the same level as the data, 
 * this function is used by @input(matRowDefWhen)
 */
  private isGroup(_index: number, _item: IMediaJobWithGrouping): boolean
  {
    return _item.isGroup;
  };

  /**
 * Used in the view to collapse a group
 * Effectively removing it from the displayed datasource
 */
  private reduceGroup(_row)
  {
    _row.reduced = !_row.reduced;
    if (_row.reduced)
    {
      this.mediaJobsReduced.push(_row);
    }
    else
    {
      this.mediaJobsReduced = this.mediaJobsReduced.filter((el) => el.value !== _row.value);
    }

    this.buildDataSource();
  };

}
