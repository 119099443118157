import { Injectable, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobalEvision5 } from '../../interfaces/IGlobalEvision5';
import { ICanBeBusy, IsBusy } from '../../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { HttpWorkerFactoryService, HttpWorker } from '../../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { IReturnState } from '../../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { evision5 } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class FileExplorerService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();
  private http: HttpWorker;

  private evision5: IGlobalEvision5 = evision5;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private router: Router,
    private httpWorkerFactory: HttpWorkerFactoryService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
  }

  public InUse(_path: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http.get<IReturnState>(this.evision5.buildApi(this.baseUrl, 'files/inuse?path=' + encodeURIComponent(_path)), {})
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  public CreateDirectory(_path: string, _newDirectoryName: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    var data = {
      name: _newDirectoryName
    };
    this.http.post<IReturnState>(this.evision5.buildApi(this.baseUrl, 'directories?path=' + encodeURIComponent(_path)), data)
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  public DeleteDirectory(_path: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http.delete<IReturnState>(this.evision5.buildApi(this.baseUrl, 'directories?path=' + encodeURIComponent(_path)))
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  public DeleteFile(_path: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http.delete<IReturnState>(this.evision5.buildApi(this.baseUrl, 'files?path=' + encodeURIComponent(_path)))
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };
}
