import { Directive, ElementRef, HostListener, Input, NgModule } from '@angular/core';
import { SecurityService } from './../core/services/common/security.service';
import { IGlobal } from './../core/interfaces/IGlobal';
import { CommonModule } from '@angular/common';

@Directive({
    selector: '[appRoleFunction]'
})
export class RoleFunctionDirective
{
    constructor(private el: ElementRef,
        private security: SecurityService)
    {
    }

    // ----

    _appRoleFunctionMode: string = 'hidden';

    get appRoleFunctionMode(): string { return this._appRoleFunctionMode; }

    @Input('appRoleFunctionMode')
    set appRoleFunctionMode(_value: string)
    {
        this._appRoleFunctionMode = _value;
    }

    // ----

    _appRoleFunction: string;

    get appRoleFunction(): string { return this._appRoleFunction; }

    @Input('appRoleFunction')
    set appRoleFunction(_value: string)
    {
        var hasRole = false;
        var rf = _value;

        if (rf)
        {
            var splitted = rf.split(',');
            for (var i = 0; i < splitted.length; i++)
            {
                if (this.security.checkForRolesByName(splitted[i], false))
                {
                    hasRole = true;
                    break;
                }
            }
        }

        if (hasRole === false)
        {
            if (this._appRoleFunctionMode === 'hidden')
            {
                this.el.nativeElement.remove();
            }
            else if (this._appRoleFunctionMode === 'disabled')
            {
                //this.el.nativeElement.setAttribute('[disabled]', 'true');
            }
        }
    }


    //@HostListener('mouseenter') onMouseEnter()
    //{
    //  this.highlight(this.highlightColor || this.defaultColor || 'red');
    //}

    //@HostListener('mouseleave') onMouseLeave()
    //{
    //  this.highlight(null);
    //}

    //private highlight(color: string)
    //{
    //  this.el.nativeElement.style.backgroundColor = color;
    //}
}

@NgModule({
  declarations: [
    RoleFunctionDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RoleFunctionDirective
  ]
})
export class RoleFunctionModule { }

