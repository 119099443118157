import { Component, Inject, EventEmitter, LOCALE_ID } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { application } from './../core/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageSelectorComponent } from '../image-selector/image-selector.component';
import { DomSanitizer } from '@angular/platform-browser';

import { UiKeyHandleService, KeyHandler } from './../core/services/common/uikeyhandle.service';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { ModulesService } from './../core/services/data/modules.service';
import { HtmlTextsService } from './../core/services/data/htmltexts.service';
import HtmlTextExtended from './../core/models/HtmlTextExtended';
import { IHtmlTextExtended } from '../core/interfaces/IHtmlTextExtended';
import { IModule } from '../core/interfaces/IModule';
import { IReturnState } from '../core/interfaces/IReturnState';
import { UtilsService } from '../core/services/common/utils.service';
import { LocaleService } from '../core/services/common/locale.service';

@Component({
  selector: 'app-htmltext-single',
  templateUrl: './htmltext-single.component.html',
  styleUrls: ['./htmltext-single.component.css']
})

export class HtmlTextSingleComponent
{
  private http: HttpWorker;
  private busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private dialogImageSelect: MatDialogRef<ImageSelectorComponent>;
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public htmlTextID: number = null;
  private htmlText: IHtmlTextExtended = new HtmlTextExtended();
  private html: string = '';

  public modules: IModule[] = [];

  private initiated: boolean = false;

  private textModule: string = application.getRawText('pwp.htmltext.single.form.module.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  private keyHandler: KeyHandler;

  private editorSettings = {
    height: 500,
    menubar: true,
    language: null,
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime table paste code help wordcount image-pwp'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | removeformat | image-pwp | help'
  };

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dateTimeService: DateTimeService,
    private modulesService: ModulesService,
    private utilsService: UtilsService,
    private htmlTextsService: HtmlTextsService,
    private localeService: LocaleService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.htmlTextsService);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());

    this.editorSettings.language = this.localeService.getLanguageForEditor();
  };

  private ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }

    var tmpID = parseInt(this.route.snapshot.paramMap.get('id'));
    this.htmlTextID = tmpID > 0 ? tmpID : null;
    this.initHtmlText();
  };

  public initHtmlText()
  {
    if (this.initiated)
    {
      return;
    }

    if (!(this.htmlTextID > 0))
    {
      this.htmlTextID = null;
      this.htmlText = new HtmlTextExtended();
    }
    else
    {
      this.loadHtmlText();
    }

    this.initiated = true;
  };

  private loadHtmlText()
  {
    this.htmlTextsService.GetHtmlText(this.htmlTextID).subscribe((_data) =>
    {
      var e = document.createElement('div');

      this.htmlText = _data as IHtmlTextExtended;
      this.setHtml();
    });
  };

  private save()
  {
    this.getHtml();
    this.htmlTextsService.SaveHtmlText(this.htmlText).subscribe((_result: IReturnState) =>
    {
      if (_result.success)
      {
        this.htmlText = _result.data as IHtmlTextExtended;
        this.htmlTextID = this.htmlText.moduleID;
        this.setHtml();
        this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
      }
      else
      {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  private setHtml()
  {
    if (this.htmlText !== null && this.htmlText.desktopHtml !== null)
    {
      this.html = this.utilsService.UnescapeHtml(this.htmlText.desktopHtml);
    }
  };

  private getHtml()
  {
    if (this.htmlText !== null && this.htmlText.desktopHtml !== null)
    {
      this.htmlText.desktopHtml = this.utilsService.EscapeHtml(this.html);
    }
  };

  private close()
  {
    this.router.navigate(['pwp', 'htmltexts']);
  };

}
